import { FC } from "react";
import Button from "../../atoms/button/Button";
import Paragraph from "../../atoms/paragraph/Paragraph";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";

type TypeCounterClasses = {
  container: string;
  label: string;
  counter: string;
  button: string;
};

interface CounterControlProps {
  count: number;
  onIncrement: () => void;
  onDecrement: () => void;
  label?: string;
  classes?: Partial<TypeCounterClasses>;
  className?: string
  isDisabled?: boolean;
  onChange?: (value: number) => void;
  isInput?: boolean;
}

const CounterControl: FC<CounterControlProps> = ({
  count,
  onIncrement,
  onDecrement,
  onChange,
  isInput = false,
  label,
  className,
  classes = { container: "", label: "", counter: "", button: "" },
  isDisabled = false
}) => {

  return (
    <div className={`flex items-center justify-between mt-0 2xl:mt-[48px] ${className}`}>
      <Paragraph
        className="text-[#282D46] font-inter-semibold"
        paragraph={label ?? ""}
      />
      <div
        className={`flex border-[1px] items-center justify-between border-border rounded-[8px] w-[140px] ${classes.container}`}
      >
        {isInput ? (
          <input type="number" onChange={onChange as any} value={count}
            className="flex-1 text-center text-primaryTextColor font-inter-semibold outline-none shadow-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          />
        ) : (
          <p className="text-[#282D46] text-[14px] font-inter-semibold mx-auto">
            {count}
          </p>
        )}

        <div>
          <Button
            disabled={isDisabled}
            type="button"
            className="border-l-[1px] border-border p-[11px] 2xl:p-[14px]"
            onClick={onDecrement}
          >
            <Icon iconType={IconType.DECRASE_ICON} />
          </Button>
          <Button
            disabled={isDisabled}
            type="button"
            className="border-l-[1px] border-border p-[11px] 2xl:p-[14px]"
            onClick={onIncrement}
          >
            <Icon iconType={IconType.INCRASE_ICON} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CounterControl;
