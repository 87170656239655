import { memo } from "react";

function LeadCostIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.289062" width="39.2346" height="40" rx="5" fill="#50B8E7" fillOpacity="0.05" />
            <path d="M29.1463 18.5891C28.5629 18.0047 27.6184 18.0045 27.0349 18.5891C23.7541 21.8753 29.4878 16.1321 21.2266 24.4069C18.5487 27.0889 14.1363 25.3637 14.2258 21.6898C14.253 20.5725 14.7148 19.5097 15.5261 18.697L21.3344 12.8793C21.9178 12.295 21.918 11.349 21.3344 10.7645L19.012 8.43834C18.4285 7.85391 17.4841 7.85386 16.9006 8.43834C16.0725 9.26791 11.8208 13.5264 10.9234 14.4253C8.98011 16.3717 7.90859 18.9597 7.90625 21.7123C7.90163 27.2365 12.2945 31.9947 18.1698 32C20.9953 32 23.6042 30.9072 25.5493 28.959L31.4686 23.03C32.052 22.4458 32.0522 21.4997 31.4686 20.9153L29.1463 18.5891ZM17.9563 9.49575L20.2787 11.822L17.3698 14.7357L15.0473 12.4095L17.9563 9.49575ZM24.4936 27.9016C21.0578 31.343 15.3934 31.3917 11.9676 27.9481C8.54572 24.5084 8.55085 18.9165 11.979 15.4826L13.9916 13.4668L16.3141 15.793L14.4704 17.6397C12.2253 19.8883 12.1356 23.464 14.3014 25.6333C16.4552 27.7906 20.0353 27.7146 22.2822 25.4642L24.1259 23.6175L26.4483 25.9437L24.4936 27.9016ZM27.5041 24.8864L25.1816 22.5602L28.0906 19.6464L30.4131 21.9726L27.5041 24.8864Z" fill="#50B8E7" />
        </svg>
    );
}
export default memo(LeadCostIcon);
