import { FunctionComponent, Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RoutesApp from "../Routes";
import Layout from "components/templates/credential-management-layout/Layout";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Leads from "pages/Leads/Leads";
import SourceView from "pages/Source/SourceView";
import CampaignView from "pages/Campaign/CampaignView";
import { Security } from "pages/Security/Security";
import withPermission from "lib/helpers/hoc/withPermission";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import EmployeesManagement from "pages/EmployeesManagement/EmployeesManagement";
import CreateEmployeesManagement from "components/organisms/employees-management/create-employees-management/CreateEmployeesManagement";
import Customers from "pages/Customers/Customers";
import EmployeeProfile from "pages/EmployeeProfile/EmployeeProfile";
import AppointmentManagementView from "components/organisms/appointment-management/AppointmentManagementView";
import CustomerSecurity from "pages/CustomerSecurity/CustomerSecurity";
import SidebarContextProvider from "lib/context/SidebarContext/SidebarContextProvider";
import Overview from "pages/Overview/Overview";
import UpdateEmployeesManagement from "components/organisms/employees-management/update-employees-management/UpdateEmployeesManagement";
import Partners from "pages/Partners/Partners";
import Pendings from "pages/Pendings/Pendings";
import StatisticsView from "pages/Statistics/StatisticsView";
import InsuranceDocumentsView from "pages/InsuranceDocuments/InsuranceDocumentsView";
import Finances from "pages/Finances/Finances";
import MonthlySuccess from "pages/Finances/MonthlySuccess";
import AnalysisCost from "pages/Finances/AnalysisCost";
import FinanceComparison from "pages/Finances/FinanceComparison";
import FinanceCommission from "pages/Finances/FinanceCommission";
import FinanceSalaries from "pages/Finances/FinanceSalaries";
import EmployeeRoles from "pages/EmployeeRoles/EmployeeRoles";

const AppointmentManagement = lazy(
  () => import("pages/AppointmentManagement/AppointmentManagement")
);

export interface IAuthenticatedRoutes { }
const AuthenticatedRoutes: FunctionComponent<IAuthenticatedRoutes> = () => {
  const router = createBrowserRouter([
    {
      path: "/dashboard",
      element: (
        <Layout>
          <Overview />
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Leads, ['lead_view_view'])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/source",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(SourceView, [
              'source_management_view_own',
              'source_management_view',
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/appointments",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(AppointmentManagement, [
              P["Appointment Salesman"],
              P["Appointment Admin"],
              P["Appointment Menager"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/appointments/own-appointments",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(AppointmentManagementView, [
              P["Appointment Admin"],
              P["Appointment Menager"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/campaign",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(CampaignView, [
              'campaign_management_view',
              'campaign_management_view_own',
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/security",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Security, [P["Leads Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: `hr/update/:id`,
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(CreateEmployeesManagement, [P["Hr Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/employee/update",
      element: (
        <Layout>

          <Suspense fallback={<LoadingScreen />}>
            {withPermission(EmployeeProfile, [P["Hr Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/customers",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Customers, [
              P["Costumer Admin"],
              P["Costumer Salesman"],
              P["Costumer Menager"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/customers/security",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(CustomerSecurity, [P["Costumer Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(EmployeesManagement, [P["Hr Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/roles",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(EmployeeRoles, [P["Hr Admin"]])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/employe-profile/:id",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(EmployeeProfile, [
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/update-employee/:id",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(UpdateEmployeesManagement, [
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/partners",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Partners, [
              P["Hr Admin"],
              P["Hr Menager"],
              P["Hr Admin"],
              P["Costumer Admin"],
              P["Costumer Salesman"],
              P["Costumer Menager"],
              P["Leads Salesman"],
              P["Leads Menager"],
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Appointment Menager"],
              P["Appointment Menager"]
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/pendings",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Pendings, [
              P["Hr Admin"],
              P["Hr Menager"],
              P["Hr Admin"],
              P["Costumer Admin"],
              P["Costumer Salesman"],
              P["Costumer Menager"],
              P["Leads Salesman"],
              P["Leads Menager"],
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Appointment Menager"],
              P["Appointment Menager"]
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/statistics",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(StatisticsView, [
              P["Leads Salesman"],
              P["Leads Menager"],
              P["Leads Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Finances, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/success",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(MonthlySuccess, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/analysis",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(AnalysisCost, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/comparison",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceComparison, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/commission",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceCommission, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/salaries",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceSalaries, [
              P["Leads Admin"],
              P["Appointment Admin"],
              P["Costumer Admin"],
              P["Hr Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/insurance-documents",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(InsuranceDocumentsView, [
              P["Leads Salesman"],
              P["Leads Menager"],
              P["Leads Admin"],
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
  ]);
  return (
    <SidebarContextProvider>
      <RoutesApp appRoutes={router} />
    </SidebarContextProvider>
  );
};
export default AuthenticatedRoutes;
