import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function LeaderboardSecondIcon({ className, color }: Props) {
    return (
        <svg width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6172 4.28125L9.87586 19.7049L22.1187 14.4646L10.6172 4.28125Z" fill="#C2C2C2" fillOpacity="0.944124"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.51828 13.8424L22.0781 14.3731L9.83529 19.6134L2.51828 13.8424Z" fill="#C2C2C2" fillOpacity="0.7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.7541 5.45784L9.83426 19.6138L22.0771 14.3735L22.7541 5.45784Z" fill="#C2C2C2" fillOpacity="0.7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4571 4.12463C10.9744 3.90322 11.2142 3.30441 10.9928 2.78715C10.7714 2.26989 10.1726 2.03005 9.65534 2.25145C9.13807 2.47286 8.89823 3.07167 9.11964 3.58893C9.34105 4.10619 9.93985 4.34603 10.4571 4.12463Z" fill="#C2C2C2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.1472 5.21663C23.7031 5.30298 24.2239 4.92226 24.3102 4.36627C24.3965 3.81028 24.0158 3.28956 23.4598 3.20322C22.9038 3.11687 22.3831 3.49759 22.2968 4.05358C22.2104 4.60957 22.5912 5.13029 23.1472 5.21663Z" fill="#C2C2C2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.56121 14.7518C2.1172 14.8381 2.63792 14.4574 2.72426 13.9014C2.81061 13.3454 2.42989 12.8247 1.8739 12.7384C1.31791 12.652 0.797191 13.0328 0.710847 13.5887C0.624502 14.1447 1.00522 14.6654 1.56121 14.7518Z" fill="#C2C2C2"/>
        <circle cx="22.0078" cy="29" r="14.5" fill="white" stroke="#C2C2C2"/>
        <path d="M18.0408 34.9986V33.4759L22.0806 29.5156C22.467 29.1255 22.7889 28.7789 23.0465 28.4759C23.3041 28.1728 23.4973 27.8793 23.6261 27.5952C23.7549 27.3111 23.8192 27.008 23.8192 26.6861C23.8192 26.3187 23.7359 26.0043 23.5692 25.7429C23.4026 25.4777 23.1734 25.2732 22.8817 25.1293C22.5901 24.9853 22.2586 24.9134 21.8874 24.9134C21.5049 24.9134 21.1696 24.9929 20.8817 25.152C20.5939 25.3073 20.3704 25.5289 20.2113 25.8168C20.056 26.1046 19.9783 26.4474 19.9783 26.8452H17.9727C17.9727 26.1065 18.1412 25.4645 18.4783 24.919C18.8155 24.3736 19.2795 23.9512 19.8704 23.652C20.4651 23.3527 21.1469 23.2031 21.9158 23.2031C22.6961 23.2031 23.3817 23.349 23.9727 23.6406C24.5636 23.9323 25.0219 24.3319 25.3477 24.8395C25.6772 25.3471 25.842 25.9266 25.842 26.5781C25.842 27.0137 25.7586 27.4418 25.592 27.8622C25.4253 28.2827 25.1317 28.7486 24.7113 29.2599C24.2946 29.7713 23.7094 30.3906 22.9556 31.1179L20.9499 33.1577V33.2372H26.0181V34.9986H18.0408Z" fill="#282D46"/>
        </svg>
        
    );
}
export default memo(LeaderboardSecondIcon);
