
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEditAnalysis } from 'lib/hooks/mutations/Finances/useEditAnalysis';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import { useDeleteAnalysis } from 'lib/hooks/mutations/Finances/useDeleteAnalysis';

export interface IUpdateAnalysisModalProps {
    onConfirm: () => void;
    title?: string;
    data?: {
        id: string,
        head_section: string,
        partner_id: string,
        direct_cost: string,
        indirect_cost: string,
        revenue: string,
        partner: string,
    }
    currentStep: number;
    setCurrentStep: (value: number) => void;
}
const UpdateAnalysisModal: FC<IUpdateAnalysisModalProps> = ({ onConfirm, data, currentStep, setCurrentStep }) => {

    const { t } = useTranslation();

    const { control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            head_section: data?.head_section,
            id: data?.id,
            direct_cost: data?.direct_cost,
            indirect_cost: data?.indirect_cost,
            revenue: data?.revenue,
        }
    });

    const { mutateAsync, isLoading } = useEditAnalysis()

    const onSubmit = (data: any) => {
        mutateAsync(data).then(() => {
            onConfirm && onConfirm();
        })
    };

    const { mutateAsync: deleteFunction, isLoading: isLoadingDelete } = useDeleteAnalysis()

    return (
        currentStep === 1 ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-0 flex flex-col gap-5">
                    <Controller
                        name='direct_cost'
                        control={control}
                        rules={{
                            required: `${t('Direct cost is required')}`,
                            pattern: { value: /^[0-9]+$/, message: `${t('Direct cost must be a number')}` }
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label={t('Direct Cost')}
                                placeholder={t('Enter direct cost')}
                                error={errors?.direct_cost}

                            />
                        )}
                    />
                    <Controller
                        name='indirect_cost'
                        control={control}
                        rules={{
                            required: `${t('Indirect cost is required')}`,
                            pattern: { value: /^[0-9]+$/, message: `${t('Indirect cost must be a number')}` }
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label={t('Indirect Cost')}
                                placeholder={t('Enter indirect cost')}
                                error={errors?.indirect_cost}
                            />
                        )}
                    />

                    <Controller
                        name='revenue'
                        control={control}
                        rules={{
                            required: `${t('Revenue is required')}`,
                            pattern: { value: /^[0-9]+$/, message: `${t('Revenue must be a number')}` }
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label={t('Revenue')}
                                placeholder={t('Enter the revenue')}
                                error={errors?.revenue}
                            />
                        )}
                    />


                </div>
                <div className="flex flex-row justify-end gap-5 pt-10">
                    <IconButton className="!w-full" type='button' disabled={isLoading} secondary onClick={() => { setCurrentStep(2) }}>
                        {t(`Delete`)}
                    </IconButton>
                    <IconButton className="!w-full" type='submit' disabled={isLoading}>
                        {t(`Update`)}
                    </IconButton>
                </div>
            </Form >
        ) : (
            <DeleteModal
                onConfirm={onConfirm}
                cancelFunction={setCurrentStep}
                data={{ id: data?.id as string, name: data?.partner as string }}
                deleteFunction={deleteFunction}
                isLoading={isLoadingDelete}
            />
        )
    )
}

export default withModal(UpdateAnalysisModal)