import IconButton from 'components/atoms/button/IconButton';
import Checkbox from 'components/atoms/checkbox/Checkbox';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import Label from 'components/atoms/input/input-label/InputLabel';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import Accordion from 'components/molecules/accordion/Accordion';
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody';
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useRegisterUser } from 'lib/hooks/mutations/RegisterUser/useRegisterUser';
import { useCompanyPermissions } from 'lib/hooks/queries/PermissionsModule/useCompanyPermissions';
import React, { FC } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
interface IStep2CreateRoleProps {
    setCurrentStep: (step: number) => void;
    setAllData: (data: any) => void;
    AllData: any;
}

const Step2CreateRole: FC<IStep2CreateRoleProps> = ({ setCurrentStep, AllData, setAllData }) => {

    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            role: AllData?.role || '',
        }
    })

    const { data, isLoading } = useCompanyPermissions()

    const handleCheckboxChange = (id: any, e: any) => {
        if (e.target.checked) {
            setAllData({ ...AllData, permission: [...AllData.permission, id] })
        }
        else {
            setAllData({ ...AllData, permission: AllData.permission.filter((permission: any) => permission !== id) })
        }
    }

    const handleReset = () => {
        setAllData({
            permission: [],
        })
        reset()
    }

    const { mutateAsync, isLoading: isLoadingRegister } = useRegisterUser()

    const handleFormSubmit = (data: any) => {

        mutateAsync({...AllData, ...data}).then((res) => {
            setCurrentStep(3)
            setAllData({ ...AllData, user_id: res.id })
        })


    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="flex flex-col gap-7 items-start">
                <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
                    <Input
                        {...register(`role`, { required: 'This field is required' })}
                        label={t(`Role Name`)}
                        placeholder={t(`Enter role name`)}
                        error={errors.role}
                    />
                </div>
                <div className='w-full flex flex-col gap-4'>
                    <Label>{t(`Role Permissions`)}</Label>
                    {
                        data?.map((module: any) => {
                            return (
                                <Accordion>
                                    <AccordionTitle>{t(module.moduleName)}</AccordionTitle>
                                    <AccordionBody>
                                        {module.permission.map((permission: any) => {
                                            return (
                                                <div key={permission.id} className='flex items-center gap-2'>
                                                    <Checkbox checked={AllData?.permission.includes(permission.id)} onChange={(e) => { handleCheckboxChange(permission.id, e) }} />
                                                    <Label className='!mb-0'>{t(permission.name)}</Label>
                                                </div>
                                            )
                                        })}
                                    </AccordionBody>
                                </Accordion>
                            )
                        })
                    }

                </div>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" secondary onClick={handleReset} disabled={isLoadingRegister}>
                    {t(`Reset`)}
                </IconButton>

                <IconButton className="" type='submit' disabled={isLoadingRegister || AllData.permission.length === 0}>
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </Form>
    )
}

export default Step2CreateRole