import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { IconType } from "lib/helpers/constants/iconTypes";
import withModal from "lib/helpers/hoc/withModal";
import { useSourceForm } from "lib/hooks/forms/useSourceForm";
import { useUpdateSource } from "lib/hooks/mutations/Sources/useUpdateSource";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IUpdateSourceProps {
  onConfirm: () => void;
  source?: any;
  themeColors: {
    primary: string;
  };
}

const UpdateSource: FC<IUpdateSourceProps> = ({
  onConfirm,
  source,
  themeColors,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useSourceForm({
    defaultValues: {
      name: source.name,
      description: source.description,
      status: source.status,
      logo: source.logo,
    },
  });

  const { mutateAsync: updateSource, isLoading } = useUpdateSource(
    source.id ?? ""
  );

  const {t} = useTranslation()

  const UpdateSourceSubmit = (data: any) => {
    data.id = source.id;

    updateSource(data)
      .then(() => {
        onConfirm();
      })
      .catch(() => {});
  };
  const [EditLogo, setEditLogo] = useState(false);
  return (
    <Form
      onSubmit={handleSubmit(UpdateSourceSubmit)}
      className="mb-0 flex flex-col gap-6"
    >
      {EditLogo ? (
        <div className="relative" style={{ width: "fit-content" }}>
          <ImageDropZone
            label={t('Source logo*')}
            {...register("logo")}
            control={control}
            classes={{
              container: "max-w-[150px]",
              subContainer: "ml-0",
              wrapperLabelClassName: "!w-[100px] !h-[100px]",
            }}
            acceptParameters="image/png, image/jpg, image/jpeg"
          />
          <div
            className="absolute top-4 right-[3px] cursor-pointer"
            onClick={() => {
              setEditLogo(false);
            }}
          >
            <Icon iconType={IconType.CLOSE_X_SM_ICON} />
          </div>
        </div>
      ) : (
        <div className="relative" style={{ width: "fit-content" }}>
          <Label>{t('Source logo*')}</Label>
          <img
            src={`https://devmainhub.insusales.com/api/leads/sources/get-logo/${source.logo}`}
            className="object-cover rounded-[10px] w-[100px] h-[100px]"
            alt="img"
          />
          <div
            className="absolute top-1 right-[-10px] cursor-pointer"
            onClick={() => {
              setEditLogo(true);
            }}
          >
            <Icon iconType={IconType.EDIT_SOURCE_ICON} />
          </div>
        </div>
      )}

      <Input
        label={t('Source name*')}
        placeholder={t('Enter source name')}
        {...register("name")}
        error={errors?.name}
      />
      <TextArea
        placeholder={t('Write description ..')}
        label={t('Description')}
        {...register("description")}
        error={errors?.description}
      />
      <ToggleSwitch
        label={t('Actived*')}
        classes={{ label: "!flex flex-row-reverse gap-[15px]" }}
        {...register("status")}
        error={errors?.status}
      />
      <div className="flex gap-5 justify-end">
        <IconButton
          secondary={true}
          className={`min-w-[120px] !text-[${themeColors.primary}]`}
          type="button"
          onClick={() => {
            reset();
          }}
        >
          {t('Reset')}
        </IconButton>
        <IconButton
          className={`min-w-[120px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          disabled={isLoading}
        >
          {t('Update')}
        </IconButton>
      </div>
    </Form>
  );
};

export default withModal(UpdateSource);
