import { addSalaries } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useAddSalaries = () => {
    const queryClient = useQueryClient();
    return useMutation(addSalaries, {
        onSuccess: () => {
            toast.success("Added sucesfully");
            queryClient.invalidateQueries('salaries');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}