import { FC, useState } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "components/atoms/icon/export";
import Step1FixedSalary from "./steps/Step1FixedSalary";
import Step2TargetPerformance from "./steps/Step2TargetPerformance";
import Step3Commissions from "./steps/Step3Commissions";
import Step4Bonus from "./steps/Step4Bonus";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import { useForm } from "react-hook-form";
import { useAddSalaries } from "lib/hooks/mutations/Finances/useAddSalaries";

interface Props {
    onConfirm: () => void;
    setFormCurrentStep: any;
    currentStep: number;
    canGoBackHr?: boolean;
}

const AddNewSalaryModule: FC<Props> = ({
    onConfirm,
    currentStep,
    setFormCurrentStep,
    canGoBackHr = false
}) => {
    const { t } = useTranslation();

    const {
        control: first_step_control,
        handleSubmit: handleSubmitFirstStep,
        formState: { errors: first_step_errors },
        reset: resetStep1,
    } = useForm({
        defaultValues: {
            name: '',
            base_salary: '',
            th13_salary: 'Yes',
        }
    })

    const {
        control: second_step_control,
        handleSubmit: handleSubmitSecondStep,
        formState: { errors: second_step_errors },
        reset: resetStep2
    } = useForm({
        defaultValues: {
            target_name: '',
            target_category: null,
            target_measurement_criteria: null,
            target_value: '',
            target_frequency: 'Monthly',
        }
    })

    const {
        control: third_step_control,
        handleSubmit: handleSubmitThirdStep,
        formState: { errors: third_step_errors },
        reset: resetStep3
    } = useForm({
        defaultValues: {
            commission_id: null,

        }
    })

    const { control: fourth_step_control,
        handleSubmit: handleSubmitFourthStep,
        formState: { errors: fourth_step_errors },
        reset: resetStep4
    } = useForm({
        defaultValues: {
            bonus_amount: '',
            bonus_trigger: null,
        }
    })

    const [AllData, setAllData] = useState<any>({})

    const handleFirstStepSubmit = (data: any) => {
        setAllData({ ...AllData, ...data })
        setFormCurrentStep(currentStep + 1);
    }

    const handleSecondStepSubmit = (data: any) => {
        setAllData({ ...AllData, ...data })
        setFormCurrentStep(currentStep + 1);
    }

    const handleThirdStepSubmit = (data: any) => {
        setAllData({ ...AllData, ...data })
        setFormCurrentStep(currentStep + 1);
    }

    const { mutateAsync, isLoading } = useAddSalaries()

    const handleFourthStepSubmit = (data: any) => {
        const finalData = { ...AllData, ...data }

        mutateAsync(finalData).then(() => {
            onConfirm()
        })
    }

    const SubmitWithoutFourthStep = () => {
        mutateAsync(AllData).then(() => {
            onConfirm()
        })
    }

    const handleReset = () => {
        switch (currentStep) {
            case 1:
                resetStep1();
                break;
            case 2:
                resetStep2();
                break;
            case 3:
                resetStep3();
                break;
            case 4:
                resetStep4();
                break;
            default:
                break;
        }

    }

    return (
        <div className="mb-0 flex flex-col gap-7">
            <button
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
                onClick={() => {
                    if (canGoBackHr && currentStep === 1) {
                        onConfirm()
                    } else {
                        setFormCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
                    }

                }}
            >
                <Icon
                    iconType={IconType.BACK_BUTTON_MODAL}
                    color={"var(--theme)"}
                />
            </button>
            <div className="pl-4">
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                    <div className="flex gap-2 items-center pb-5">
                        <div className="pl-2 text-[14px] font-inter-medium text-primaryTextColor">
                            {t('Fixed Salary')}
                        </div>
                        <div>
                            <Icon iconType={IconType.TOOLTIP_INFO_ICON} />
                        </div>
                        {/* {currentStep === 1 && (
                            <div className="ml-auto">
                                <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" onClick={() => { setFormCurrentStep(2) }} >
                                    {t("Proceed without ->")}
                                </span>
                            </div>
                        )} */}
                    </div>
                    <ConditionalRender condition={currentStep === 1}>
                        <Form id="step_form_1" onSubmit={handleSubmitFirstStep(handleFirstStepSubmit)}>
                            <Step1FixedSalary control={first_step_control} errors={first_step_errors} />
                        </Form>
                    </ConditionalRender>
                    <div className="absolute top-[0] left-[-12px] z-9999" >
                        {currentStep <= 1 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                1
                            </div>
                        ) : <CheckIcon />}

                    </div>
                </div>
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                    <div className="flex gap-2 items-center pb-5">
                        <div className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${currentStep < 2 ? 'opacity-40' : ''}`}>
                            {t('Target Performance')}
                        </div>

                        {currentStep === 2 && (
                            <div className="ml-auto">
                                <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" onClick={() => { setFormCurrentStep(3) }}>
                                    {t("Proceed without ->")}
                                </span>
                            </div>
                        )}

                    </div>
                    <ConditionalRender condition={currentStep === 2}>
                        <Form id="step_form_2" onSubmit={handleSubmitSecondStep(handleSecondStepSubmit)}>
                            <Step2TargetPerformance control={second_step_control} errors={second_step_errors} />
                        </Form>
                    </ConditionalRender>
                    <div className="absolute top-[0] left-[-12px] z-9999" style={{ opacity: currentStep < 2 ? 0.4 : 1 }}>
                        {currentStep <= 2 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                2
                            </div>
                        ) : <CheckIcon />}

                    </div>
                </div>
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                    <div className="flex gap-2 items-center pb-5">
                        <div className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${currentStep < 3 ? 'opacity-40' : ''}`}>
                            {t('Commissions')}
                        </div>

                        {currentStep === 3 && (
                            <div className="ml-auto">
                                <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" onClick={() => { setFormCurrentStep(4) }}>
                                    {t("Proceed without ->")}
                                </span>
                            </div>
                        )}

                    </div>
                    <ConditionalRender condition={currentStep === 3}>
                        <Form onSubmit={handleSubmitThirdStep(handleThirdStepSubmit)} id="step_form_3">

                            <Step3Commissions control={third_step_control} errors={third_step_errors} />
                        </Form>
                    </ConditionalRender>
                    <div className="absolute top-[0] left-[-12px] z-9999" style={{ opacity: currentStep < 3 ? 0.4 : 1 }}>
                        {currentStep <= 3 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                3
                            </div>
                        ) : <CheckIcon />}

                    </div>
                </div>
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 relative bg-transparent">
                    <div className="flex gap-2 items-center">
                        <div className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${currentStep < 4 ? 'opacity-40' : ''}`}>
                            {t('Bonus')}
                        </div>

                        {currentStep === 4 && (
                            <div className="ml-auto">
                                <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" onClick={SubmitWithoutFourthStep} >
                                    {t("Proceed without ->")}
                                </span>
                            </div>
                        )}

                    </div>
                    <ConditionalRender condition={currentStep === 4}>

                        <Form onSubmit={handleSubmitFourthStep(handleFourthStepSubmit)} id="step_form_4">
                            <Step4Bonus control={fourth_step_control} errors={fourth_step_errors} />
                        </Form>
                    </ConditionalRender>
                    <div className="absolute top-[0] left-[-12px] z-9999" style={{ opacity: currentStep < 4 ? 0.4 : 1 }}>
                        {currentStep <= 4 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                4
                            </div>
                        ) : <CheckIcon />}

                    </div>
                </div>
            </div>
            <div className="pt-5 flex justify-end gap-3">
                <IconButton secondary className="max-w-[167px] w-full" disabled={isLoading} onClick={handleReset}>
                    {t('Reset')}
                </IconButton>
                <IconButton className="max-w-[167px] w-full" form={`step_form_${currentStep}`} disabled={isLoading}>
                    {
                        currentStep === 4 ? t('Submit') : (
                            <span>
                                {t('Next Step')} -{'>'}
                            </span>
                        )
                    }

                </IconButton>
            </div>
        </div>
    );
};

export default withModal(AddNewSalaryModule);