import React, { memo, useState, useMemo, Children, useCallback } from "react";
import { AccordionProps } from "lib/types/accordion.types";
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle";
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody";

const defaultBodyStyle = "bg-transparent flex items-center justify-between";

function Accordion(props: AccordionProps): React.ReactElement {
  const {
    children,
    classes = { wrapper: "", title: "", body: "" },
    open,
    ...rest
  } = props;
  
  const [localOpen, setLocalOpen] = useState<boolean>(false);

  const toggleAccordion = useCallback(() => {
    setLocalOpen((prev) => !prev);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOpen]);

  const processedChildren = useMemo(() => {
    return Children.map(children, (child, index) => {
      if (!React.isValidElement(child)) return child;

      const isOpen = open !== undefined ? open : localOpen;

      if (index === 0) {
        return (
          <AccordionTitle
            {...child.props}
            open={isOpen}
            onClick={toggleAccordion}
            accordionTitleClass={classes.title}
          />
        );
      }

      if (index === 1) {
        return (
          <AccordionBody
            {...child.props}
            open={isOpen}
            accordionBodyClass={`${isOpen ? defaultBodyStyle : ""} ${
              classes.body
            }`}
          />
        );
      }

      return child;
    });
  }, [children, localOpen, open, toggleAccordion, classes.title, classes.body]);

  return (
    <div
      className={`border-[1px] text-[#667085]  text-[14px] border-inputborder rounded-[8px] w-full p-[12px]  font-inter-regular ${classes.wrapper}`}
      {...rest}
    >
      {processedChildren}
    </div>
  );
}

Accordion.Title = memo(AccordionTitle);
Accordion.Body = memo(AccordionBody);

export default memo(Accordion);
