
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import withModal from 'lib/helpers/hoc/withModal'
import Creatable from "react-select/creatable";

import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import Label from 'components/atoms/input/input-label/InputLabel';
import { customStyles } from 'lib/helpers/configs/customStyles';
import { usePartners } from 'lib/hooks/queries/Partners/usePartnersForFilter';
import { useStoreSuccessCompany } from 'lib/hooks/mutations/Finances/useStoreSuccessCompany';
import { useUpdateSuccessCompany } from 'lib/hooks/mutations/Finances/useUpdateSuccessCompany';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import { useDeleteSuccessCompany } from 'lib/hooks/mutations/Finances/useDeleteSuccessCompany';

export interface IAddNewSuccessCompanyModalProps {
    onConfirm: () => void;
    section_id?: string
    data: any;
    currentStep: number;
    setCurrentStep: (value: number) => void;
}
const AddNewSuccessCompanyModal: FC<IAddNewSuccessCompanyModalProps> = ({ onConfirm, section_id, data, currentStep, setCurrentStep }) => {

    const { t } = useTranslation();
    const { data: partnersData, isLoading: isLoadingPartners } = usePartners();

    const { control,
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        defaultValues: {
            id: data ? data.id : null,
            section_id: section_id,
            partner_id: data ? data.partner_id.id : '',
            amount: data ? data.amount : '',
        }
    });

    const { mutateAsync, isLoading } = useStoreSuccessCompany()
    const { mutateAsync: updateAsync, isLoading: isLoadingAsync } = useUpdateSuccessCompany()

    const onSubmit = (formData: any) => {

        if (data) {
            updateAsync(formData).then(() => {
                onConfirm();
            })

        } else {
            mutateAsync(formData).then(() => {
                onConfirm();
            })
        }


    };

    const { mutateAsync: deleteFunction, isLoading: isLoadingDelete } = useDeleteSuccessCompany()

    const [DeleteData, setDeleteData] = useState({ id: { section_id: '', id: '' }, name: '' })

    if (isLoadingPartners) {
        return <LoadingScreen />
    }
    return (
        currentStep === 1 ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-0 flex flex-col gap-5">
                    <div className="relative">
                        <Label text={t('Partner')} />
                        <Controller
                            name="partner_id"
                            control={control}
                            rules={{ required: `${t('Partner is required')}` }}
                            render={({ field }) => (
                                <Creatable<any, false>
                                    {...field}
                                    placeholder={t('Choose partner')}
                                    options={
                                        (
                                            partnersData?.partners as unknown as Array<{
                                                name: string;
                                                id: string;
                                            }>
                                        )?.map?.((user) => ({
                                            label: user?.name,
                                            value: user?.id,
                                        })) ?? []
                                    }
                                    value={
                                        field?.value
                                            ? {
                                                value: field?.value,
                                                label:
                                                    (
                                                        partnersData?.partners?.find?.(
                                                            (user: any) => user.id === field.value
                                                        ) as { name: string } | undefined
                                                    )?.name || field.value,
                                            }
                                            : null
                                    }
                                    className="cursor-pointer font-inter-regular react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption
                                            ? selectedOption.value
                                            : null;
                                        field.onChange(selectedValue);

                                    }}
                                    styles={customStyles}
                                    isValidNewOption={() => false}
                                    menuPosition={"fixed"}
                                    menuPortalTarget={document.body}
                                />
                            )}
                        />
                        {errors.partner_id?.message && (
                            <>
                                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                    <>{errors.partner_id?.message}</>
                                </div>
                            </>
                        )}
                    </div>
                    <Controller
                        name='amount'
                        control={control}
                        rules={{
                            required: `${t('Amount is required')}`,
                            pattern: { value: /^[0-9]+$/, message: `${t('Amount must be a number')}` }
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label={t('Amount')}
                                placeholder={t('Enter the amount')}
                                {...register("amount")}
                                error={errors?.amount}

                            />
                        )}
                    />
                    <div className="flex flex-row justify-end gap-5 pt-10">
                        {data && (
                            <IconButton
                                className="!w-full"
                                type="button"
                                secondary
                                disabled={isLoading || isLoadingAsync}
                                onClick={() => { setCurrentStep(2); setDeleteData({ id: { section_id: section_id as string, id: data?.id }, name: '?' }) }}
                            >
                                Delete
                            </IconButton>
                        )}
                        <IconButton
                            className={`${data ? '!w-full' : ''}`}
                            type="submit"
                            icon={!data && <Icon iconType={IconType.PLUS_ICON} />}
                            disabled={isLoading || isLoadingAsync}
                        >
                            {data ? t('Update') : t('Add new')}
                        </IconButton>
                    </div>
                </div>

            </Form >
        ) : (
            <DeleteModal
                onConfirm={onConfirm}
                cancelFunction={setCurrentStep}
                data={DeleteData as any}
                deleteFunction={deleteFunction}
                isLoading={isLoadingDelete}
            />
        )

    )
}

export default withModal(AddNewSuccessCompanyModal)