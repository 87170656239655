import { memo } from "react";
interface Props {
    className?: string;
}
const EmptyDocumentIcon = ({ className }: Props) => {
    return (
        <svg className={className} viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9171_61654)">
                <path d="M148.459 30.2109H19.7478C18.0114 30.212 16.3463 30.9023 15.1184 32.1302C13.8906 33.358 13.2003 35.0231 13.1992 36.7595V154.854C13.2003 156.59 13.8906 158.255 15.1184 159.483C16.3463 160.711 18.0114 161.401 19.7478 161.402H148.459C150.196 161.401 151.861 160.711 153.089 159.483C154.317 158.255 155.008 156.59 155.009 154.854V36.7595C155.008 35.023 154.317 33.3578 153.089 32.1299C151.861 30.902 150.196 30.2118 148.459 30.2109Z" fill="#D9F3FF" />
                <path d="M139.986 120.369H28.2224C24.606 120.369 21.6738 117.437 21.6738 113.821V9.47829C21.6738 5.86182 24.606 2.92969 28.2224 2.92969H139.986C143.603 2.92969 146.535 5.86182 146.535 9.47829V113.821C146.535 117.437 143.603 120.369 139.986 120.369Z" fill="#EFFAFF" />
                <path d="M23.7471 115.893V11.5516C23.7471 7.93514 26.6793 5.00301 30.2958 5.00301H142.06C143.337 5.00301 144.524 5.37426 145.531 6.00653C144.374 4.16043 142.326 2.92969 139.986 2.92969H28.2224C24.606 2.92969 21.6738 5.86182 21.6738 9.47829V113.821C21.6738 116.161 22.9046 118.208 24.7507 119.366C24.1184 118.359 23.7471 117.171 23.7471 115.894V115.893Z" fill="#BFE5F6" />
                <path d="M126.235 24.9766H41.9746V29.7785H126.235V24.9766ZM126.235 43.3118H41.9746V48.1138H126.235V43.3118ZM126.235 61.6512H41.9746V66.4531H126.235V61.6512ZM126.235 79.9865H41.9746V84.7891H126.235V79.9865Z" fill="white" />
                <path d="M52.0156 64.4844L73.1891 85.6585H52.0156V64.4844Z" fill="#B9E5F9" />
                <path d="M155.009 87.403V38.6854L146.535 30.2109V87.4024H155.009V87.403Z" fill="#D9F3FF" />
                <path d="M164.934 91.8137L155.805 156.442C155.348 159.673 152.583 162.075 149.32 162.075H18.8877C15.6249 162.075 12.8598 159.673 12.4033 156.442L0.0655295 69.1133C-0.491007 65.1709 2.56826 61.6484 6.54989 61.6484H46.8302C50.093 61.6484 52.8581 64.0497 53.3145 67.2801L54.9314 78.7165C55.3879 81.9475 58.153 84.3488 61.4158 84.3488H158.451C162.432 84.3488 165.492 87.8713 164.935 91.8137H164.934Z" fill="#D9F3FF" />
                <path d="M132.587 133.244H35.6227C35.0912 133.243 34.578 133.049 34.1793 132.698C33.7806 132.347 33.5237 131.862 33.4567 131.335L32.2435 121.73C32.205 121.422 32.2322 121.11 32.3235 120.814C32.4147 120.518 32.5678 120.245 32.7726 120.013C32.9774 119.781 33.2293 119.595 33.5115 119.467C33.7937 119.34 34.0998 119.274 34.4095 119.273H133.8C134.109 119.274 134.415 119.34 134.698 119.467C134.98 119.595 135.232 119.781 135.437 120.013C135.641 120.245 135.795 120.518 135.886 120.814C135.977 121.11 136.004 121.422 135.966 121.73L134.753 131.335C134.686 131.862 134.429 132.347 134.03 132.698C133.631 133.049 133.118 133.243 132.587 133.244Z" fill="#BFE5F6" />
            </g>
            <defs>
                <clipPath id="clip0_9171_61654">
                    <rect width="165" height="165" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default memo(EmptyDocumentIcon);
