import { addExternalInternalCost } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useExternalInternalCost = (type?: string) => {
    const queryClient = useQueryClient();
    return useMutation(addExternalInternalCost, {
        onSuccess: () => {
            toast.success("Cost added sucesfully");
            queryClient.invalidateQueries(type || 'externalCosts');
            queryClient.invalidateQueries('costsCardStatistics');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}