import React, { FC, useEffect, useState } from 'react'
import SalariesTable from '../../salaries-table/SalariesTable';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/atoms/checkbox/Checkbox';
import { Controller } from 'react-hook-form';
import { useCommissions } from 'lib/hooks/queries/Finances/useCommissions';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import Portrait from '../../../../../assets/images/portrait.png'
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';


interface Props {
  control: any;
  errors: any;
}

const Step3Commissions: FC<Props> = ({ control, errors }) => {

  const { t } = useTranslation()

  const [CauseRerender, setCauseRerender] = useState<number | null>(1)

  const tableHeaders = [
    {
      id: 1,
      Header: ``,
      accessor: `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <Controller
          control={control}
          name='commission_id'
          render={({ field }) => (
            <Checkbox id={row.original.id}
              isChecked={field.value === row.original.id}

              onChange={(e: any) => {
                if (e.target.checked) {
                  field.onChange(row.original.id)
                  setCauseRerender(row.original.id)
                }
                else {
                  field.onChange(null)
                  setCauseRerender(null)
                }
              }}
            />
          )}

        />

      ),
      isChecked: true
    },
    {
      id: 2,
      Header: `${t('Name')}`,
      accessor: (row: any) =>
        row?.name,
      Cell: ({ row }: any) => (
        <div className="cursor-pointer">
          {row.original?.name}
        </div>
      ),
      isChecked: true
    },
    {
      id: 3,
      Header: `${t('Assigned Workers')}`,
      accessor: (row: any) =>
        row.info?.first_name,
      Cell: ({ row }: any) => (
        <div className="cursor-pointer flex">
          <img src={Portrait} alt="" />
          <img src={Portrait} alt="" className='-ml-3' />
          <img src={Portrait} alt="" className='-ml-3' />
          <img src={Portrait} alt="" className='-ml-3' />
        </div>
      ),
      isChecked: true
    },
    {
      id: 4,
      Header: `${t('Insurances')}`,
      accessor: (row: any) =>
        row?.companies?.length || 0,
      Cell: ({ row }: any) => (
        <div className="cursor-pointer">

          {row?.original?.companies?.length || 0}
        </div>
      ),
      isChecked: true
    },
    {
      id: 5,
      Header: `${t('View')}`,
      accessor: (row: any) =>
        row.info?.first_name,
      Cell: ({ row }: any) => (
        <div className="cursor-pointer">
          <Icon iconType={IconType.SHOW_PASSWORD_ICON} />
        </div>
      ),
      isChecked: true
    },
  ]

  const [ReRenderedCards, setReRenderedCards] = useState(tableHeaders)

  useEffect(() => {
    setReRenderedCards(tableHeaders)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CauseRerender])


  const { data, isLoading } = useCommissions('')

  if (isLoading) {
    return <div className="relative py-10"><LoadingScreen /></div>
  }

  return (
    <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
      <SalariesTable
        data={data || []}
        dataCols={ReRenderedCards}
        background='transparent'
      />
    </div>
  )
}

export default Step3Commissions