import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getRolePermissions } from "api/Employee";


export const useRolePermissions = (role_id?: number) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['rolePermissions'],
        () => {
            return getRolePermissions({
                role_id: role_id
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
