import Icon from "components/atoms/icon/Icon";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import AccessSource from "components/organisms/source/customer-access-source/AccessSource";
import DeleteSource from "components/organisms/source/delete-source/DeleteSource";
import UpdateSource from "components/organisms/source/update-source/UpdateSource";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useUpdateSourceStatus } from "lib/hooks/mutations/Sources/useUpdateSourceStatus";
import React from "react";
import { FC, useState } from "react";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface ISourceCardProps {
  id?: number;
  Insurance?: string;
  date?: string;
  campaigns?: number;
  totalleads?: number;
  late?: number;
  unedited?: number;
  New?: number;
  active?: number | string;
  logo?: string | null;
  description?: string;
}

const SourceCard: FC<ISourceCardProps> = ({
  id,
  Insurance,
  date,
  campaigns,
  totalleads,
  late,
  unedited,
  New,
  active,
  logo,
  description,
}) => {
  const source = {
    id,
    name: Insurance,
    description,
    date,
    campaigns,
    totalleads,
    late,
    unedited,
    New,
    status: active,
    logo,
  };

  const [ThreeDotsOpen, setThreeDotsOpen] = useState<boolean>(false);
  const { confirm } = useModalContext();
  const hasPermissionToEditSource = usePermissions(['source_management_edit']);
  const hasPermissionToDeleteSource = usePermissions(['source_management_delete']);
  const { themeColors } = useThemeContext();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>();

  const [isActive, setIsActive] = useState(active === 1);

  const { mutateAsync: updateSourceStatus } = useUpdateSourceStatus();
  const toggleStatus = async (e: any) => {
    updateSourceStatus({
      source_id: id,
    })
      .then((res) => {
        if (res.status) {
          setIsActive(!e.target.checked);
        }
      })
      .catch(() => { });
  };

  const { t } = useTranslation();

  const OpenCustomerAccessModal = async (source: any) => {
    try {
      await confirm({
        title: t("Customer Access"),
        classes: {
          modal: "max-w-[551px] md-max-w-full lg:max-w-[551px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <AccessSource
              themeColors={themeColors}
              source={source}
              onConfirm={props.onConfirm}
            />
          );
        },
      });
    } catch (err) {
      return null;
    }
  };

  const OpenDeleteModal = async (data: Record<string, any>) => {
    try {
      await confirm({
        title: t("Are you sure?"),
        classes: {
          modal: "max-w-[551px] md-max-w-full lg:max-w-[551px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <DeleteSource
              themeColors={themeColors}
              data={data ?? []}
              onCancel={props.onCancel}
              id={id}
              onConfirm={props.onConfirm}
            />
          );
        },
      });
      setThreeDotsOpen(false);
    } catch (err) {
      return null;
    }
  };

  React.useEffect(() => {
    setIsActive(active === 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return (
    <div className="w-full relative flex flex-col max-w-screen-sm border gap-3 border-solid border-gray-200 rounded-2xl  p-3">
      <div className="flex pb-2 items-center justify-between border-">
        <div className="flex flex-row items-center py-1 px-1 ">
          {logo ? (
            <img
              src={
                "https://devmainhub.insusales.com/api/leads/sources/get-logo/" + logo
              }
              alt="Logo"
              className="object-cover rounded-full w-[45px] h-[45px]"
            />
          ) : (
            <div className="w-full h-full text-gray-400 flex justify-center items-center">
              {t('No Logo')}
            </div>
          )}
          <div className="flex flex-col px-4 ">
            <div className="font-inter-medium text-sm/[16px]">{Insurance}</div>
            <div className="font-inter-regular text-[11px] pt-1 text-secondaryTextColor">
              {date}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="px-1">
            <ToggleSwitch
              initialValue={undefined}
              checked={isActive}
              onChange={toggleStatus}
              classes={{ label: "!mb-0" }}
            />
          </div>
            <button
              type="button"
              className="px-2"
              onClick={() => {
                setThreeDotsOpen(!ThreeDotsOpen);
              }}
            >
              <Icon
                iconType={IconType.TOOLTIP_THREE_DOTS}
                className="w-[5.16px] h-[22.9px]"
              />

              <div>
                {ThreeDotsOpen && (
                  <>
                    <DynamicDropdown
                      setIsOpen={setThreeDotsOpen}
                      showCloseButton={false}
                    >
                      <div>
                      <ConditionalRender condition={hasPermissionToEditSource}>
                        <div
                          className="pb-3 flex flex-row items-center"
                          onClick={() => {
                            setUpdateData(source);
                            setIsUpdateModalOpen(true);
                          }}
                        >
                          <div>
                            <Icon iconType={IconType.EDIT_SOURCE_ICON} />
                          </div>
                          <div className="px-3 text-[14px] font-inter-medium">
                            {t('Edit')}
                          </div>
                        </div>
                        <div
                          className="pb-3 flex flex-row items-center"
                          onClick={OpenCustomerAccessModal}
                        >
                          <div>
                            <Icon iconType={IconType.CUSTOMER_ACCES_ICON} />
                          </div>
                          <div className="px-3 text-[14px] font-inter-medium">
                            {t('Customer Access')}
                          </div>
                        </div>
                      </ConditionalRender>
                      <ConditionalRender condition={hasPermissionToDeleteSource}>
                        <div
                          className="flex flex-row items-center"
                          onClick={OpenDeleteModal}
                        >
                          <div>
                            <Icon iconType={IconType.DELETE_SOURCE_ICON} />
                          </div>
                          <div className="px-3 text-[14px] font-inter-medium">
                            {t('Delete')}
                          </div>
                        </div>
                        </ConditionalRender>
                      </div>
                    </DynamicDropdown>
                  </>
                )}
              </div>
            </button>
        </div>
      </div>
      <div className="flex pt-3  gap-2 items-center flex-start">
        <div className="rounded-2xl text-center 2xl:px-6  xl:px-4 lg:px-5 md:px-8  p-2  font-inter-semibold text-sm/[10px] bg-[#EF444433] text-[#EF4444]">
          {late} {t('Late')}
        </div>
        <div className="rounded-2xl text-center 2xl:px-12  xl:px-3 lg:px-6 md:px-16  p-2 font-inter-semibold text-sm/[10px] bg-[#50B8E733] text-[#50B8E7]">
          {unedited} {t('Unedited')}
        </div>
        <div className="rounded-2xl text-center 2xl:px-6 xl:px-4 lg:px-5 md:px-8  p-2  font-inter-semibold text-sm/[10px] bg-[#66BB6A33] text-[#66BB6A]">
          {New} {t('New')}
        </div>
      </div>
      <div className="flex flex-row justify-between text-sm p-2 pt-5 font-inter-regular items-center">
        <div>{t('Campaigns')}</div>
        <div>{campaigns}</div>
      </div>
      <div className="flex flex-row justify-between text-sm px-2 font-inter-regular">
        <span>{t('Total leads')}</span>
        <span>{totalleads}</span>
      </div>
      <ConditionalRender condition={isUpdateModalOpen as boolean}>
        <UpdateSource
          onConfirm={() => setIsUpdateModalOpen(false)}
          themeColors={themeColors}
          hocClasses={{ modalClassName: 'max-w-[551px]' }}
          source={updateData}
          hocCurrentStep={1}
          hocToggleModal={
            () => setIsUpdateModalOpen(false)
          }
          hocisOpen={isUpdateModalOpen}
          hocTitle={t("Create New Source")}
          setHocCurrentStep={() => { }}
          clearData={true}
        />
      </ConditionalRender>
    </div>
  );
};

export default SourceCard;
