import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface IFinanceCardsProps {
    stats: {
        internal_cost: string,
        external_cost: string,
        lead_cost: string,
        app_cost: string
    }
}

const FinanceCards: FC<IFinanceCardsProps> = ({ stats }) => {
    const { themeColors } = useThemeContext();
    const { t } = useTranslation();



    const cardsData = [
        {
            title: `${t('Internal Costs')}`,
            color: themeColors.primary,
            count: (stats?.internal_cost || 0) + ' CHF',
            icon: IconType.INTERNAL_COSTS_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,
            // percentage: 10
        },
        {
            title: `${t('External Costs')}`,
            color: themeColors.primary,
            count: (stats?.external_cost || 0) + ' CHF',
            icon: IconType.EXTERNAL_COSTS_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,

            // percentage: 10

        },
        {
            title: `${t('Lead Cost')}`,
            count: (stats?.lead_cost || 0) + ' CHF',
            color: themeColors.primary,
            icon: IconType.LEAD_COST_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,

            // percentage: 10

        },
        {
            title: `${t('Appointments Cost')}`,
            count: (stats?.app_cost || 0) + ' CHF',
            color: themeColors.primary,
            icon: IconType.APPOINTMENT_COST_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,
            percentage: 10

        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5">
            {cardsData?.map(
                (
                    item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
                    index: number
                ) => (
                    <Card<IInfoCardProps>
                        cardType={CardType.INFO_CARD}
                        {...item}
                        key={`finances-cards-key-${index}`}
                    />
                )
            )}
        </div>
    );
};

export default FinanceCards;
