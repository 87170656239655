import { memo } from "react";

function AppointmentCost() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.162109" width="39.2346" height="40" rx="5" fill="#50B8E7" fillOpacity="0.05"/>
        <path d="M26.7793 14.5H23.7793V13.5C23.7793 12.7044 23.4632 11.9413 22.9006 11.3787C22.338 10.8161 21.5749 10.5 20.7793 10.5H18.7793C17.9836 10.5 17.2206 10.8161 16.658 11.3787C16.0954 11.9413 15.7793 12.7044 15.7793 13.5V14.5H12.7793C11.9836 14.5 11.2206 14.8161 10.658 15.3787C10.0954 15.9413 9.7793 16.7044 9.7793 17.5V26.5C9.7793 27.2956 10.0954 28.0587 10.658 28.6213C11.2206 29.1839 11.9836 29.5 12.7793 29.5H26.7793C27.5749 29.5 28.338 29.1839 28.9006 28.6213C29.4632 28.0587 29.7793 27.2956 29.7793 26.5V17.5C29.7793 16.7044 29.4632 15.9413 28.9006 15.3787C28.338 14.8161 27.5749 14.5 26.7793 14.5ZM17.7793 13.5C17.7793 13.2348 17.8847 12.9804 18.0722 12.7929C18.2597 12.6054 18.5141 12.5 18.7793 12.5H20.7793C21.0445 12.5 21.2989 12.6054 21.4864 12.7929C21.6739 12.9804 21.7793 13.2348 21.7793 13.5V14.5H17.7793V13.5ZM27.7793 26.5C27.7793 26.7652 27.6739 27.0196 27.4864 27.2071C27.2989 27.3946 27.0445 27.5 26.7793 27.5H12.7793C12.5141 27.5 12.2597 27.3946 12.0722 27.2071C11.8847 27.0196 11.7793 26.7652 11.7793 26.5V21.45H14.7793V22.5C14.7793 22.7652 14.8847 23.0196 15.0722 23.2071C15.2597 23.3946 15.5141 23.5 15.7793 23.5C16.0445 23.5 16.2989 23.3946 16.4864 23.2071C16.6739 23.0196 16.7793 22.7652 16.7793 22.5V21.45H22.7793V22.5C22.7793 22.7652 22.8847 23.0196 23.0722 23.2071C23.2597 23.3946 23.5141 23.5 23.7793 23.5C24.0445 23.5 24.2989 23.3946 24.4864 23.2071C24.6739 23.0196 24.7793 22.7652 24.7793 22.5V21.45H27.7793V26.5ZM27.7793 19.5H11.7793V17.5C11.7793 17.2348 11.8847 16.9804 12.0722 16.7929C12.2597 16.6054 12.5141 16.5 12.7793 16.5H26.7793C27.0445 16.5 27.2989 16.6054 27.4864 16.7929C27.6739 16.9804 27.7793 17.2348 27.7793 17.5V19.5Z" fill="#50B8E7"/>
        </svg>
        
    );
}
export default memo(AppointmentCost);
