import Header from "components/organisms/header/Header";
import Sidenav from "components/organisms/sidenav/Sidenav";
import { useSidebarContext } from "lib/context/SidebarContext/SidebarContext";
import { FC } from "react";

export interface ILayoutProps {
  children: React.ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const { isToggled } = useSidebarContext();
  return (
    <div
      className={`h-full grid w-full  p-[25px]  max-h-full ${
        isToggled ? "md:grid-cols-[220px_1fr]" : "md:grid-cols-[90px_1fr]"
      } transition-all duration-200 ease-in-out`}
    >
      <Sidenav />
      {/* min-h-screen */}
      <main
        className={` h-max-content transition-all duration-200 ease-in-out ${
          isToggled
            ? "md:pl-[36px]  md:w-[calc(100vw-280px)] "
            : "md:pl-[10px]  md:w-[calc(100vw-150px)]"
        } `}
      >
        <Header />
        <div>{children}</div>
      </main>
    </div>
  );
};

export default Layout;
