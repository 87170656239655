
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import Label from 'components/atoms/input/input-label/InputLabel';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from 'lib/helpers/configs/customStyles';
import { useAddCommisionCompany } from 'lib/hooks/mutations/Finances/useAddCommisionCompany';



export interface IAddNewCommissionProps {
    onConfirm: () => void;
    id?: string;
}

const AddNewCommission: FC<IAddNewCommissionProps> = ({ onConfirm, id }) => {

    const { t } = useTranslation();

    const { control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            commission_id: id,
            name: null,
        }
    });

    const { mutateAsync, isLoading } = useAddCommisionCompany()

    const onSubmit = (data: any) => {
        mutateAsync(data).then(() => {
            onConfirm()
        })

    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-5">
                <div className="relative">
                    <Label text={t('Insurance Type')} />
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: `${t('Insurance type is required')}` }}
                        render={({ field }) => (
                            <CreatableSelect<any, false>
                                {...field}
                                placeholder={t('Choose insurance type')}
                                options={[
                                    { label: t('KVG'), value: 'KVG' },
                                    { label: t('VVG'), value: 'VVG' },
                                    { label: t('Life'), value: 'Life' },
                                    { label: t('Lawprotection'), value: 'Lawprotection' },
                                    { label: t('Business'), value: 'Business' },
                                    { label: t('Auto'), value: 'Auto' },
                                ]}
                                value={field?.value
                                    ? {
                                        value: field?.value,
                                        label: t(field?.value),
                                    }
                                    : null}
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption
                                        ? selectedOption.value
                                        : null;
                                    field.onChange(selectedValue);

                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                            />
                        )}
                    />
                    {errors.name?.message && (
                        <>
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                <>{errors.name?.message}</>
                            </div>
                        </>
                    )}
                </div>


            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="" type='submit' icon={<Icon iconType={IconType.PLUS_ICON} />} disabled={isLoading}>
                    {t('Add commission')}
                </IconButton>
            </div>
        </Form >
    )
}

export default withModal(AddNewCommission)