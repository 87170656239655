import { ForwardRefRenderFunction, HTMLAttributes, forwardRef } from "react";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import {
  Controller,
  Control,
  DeepMap,
  FieldError,
} from "react-hook-form";
import ErrorMessage from "../../atoms/error-message/ErrorMessage";
import Input from "../../atoms/input/Input";
import { useHandleDropZoneFileChange } from "lib/hooks/shared/useHandleDropZoneFileChange";
import Label from "components/atoms/input/input-label/InputLabel";

export interface IImageDropZoneClasses {
  container?: string;
  subContainer?: string;
  label?: string;
  errorClassName?: string;
  input?: string;
  wrapperLabelClassName?: string;
}

export interface IImageDropZoneProps extends HTMLAttributes<HTMLDivElement> {
  control: Control;
  name: string;
  error?: DeepMap<any, FieldError>;
  classes?: Partial<IImageDropZoneClasses>;
  defaultValue?: string;
  label?: string;
  acceptParameters?: string;
  rules?: Record<string, any>;
}

const ImageDropZone: ForwardRefRenderFunction<
  HTMLInputElement,
  IImageDropZoneProps
> = (
  {
    control,
    error,
    rules,
    name,
    label,
    classes = { container: "", label: "", input: "", subContainer: '', errorClassName: '' , wrapperLabelClassName: ''},
    defaultValue,
    acceptParameters,
    ...rest
  },
  ref
) => {
    const { preview, handleFileChange } =
      useHandleDropZoneFileChange(defaultValue);

    return (
      <Controller
        control={control}
        name={name}
        rules={rules || undefined}
        render={({ field: { onChange } }) => (
          <div
            className={`flex justify-center relative ${classes.container}`}
            {...rest}
          >
            <div className={`m-4 ${classes.subContainer}`}>
              {label && <Label text={label} className={classes.label} />}
              <div className="flex items-center justify-center w-full">
                <label
                  className={`${classes.wrapperLabelClassName} flex flex-col cursor-pointer w-full h-32 border-[1px] rounded-[10px] border-border border-dashed bg-dropzonebg relative ${classes.label}`}
                >
                  <div className="flex flex-col items-center justify-center pt-7">
                    {preview ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="object-contain absolute top-0 right-0  w-full left-0 bottom-0 rounded-[10px] h-[-webkit-fill-available]"
                      />
                    ) : (
                      <Icon
                        iconType={IconType.UPLOAD_ICON}
                        className="absolute top-[40%]"
                      />
                    )}
                  </div>
                  <Input
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                      const file = e.target.files?.[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => onChange(reader.result);
                        reader.readAsDataURL(file);
                      }
                    }}
                    accept={acceptParameters ? acceptParameters : "image/*"}
                    id={`${name}-input"}`}
                    className={`opacity-0 ${classes.input}`}
                    ref={ref}
                  />
                  <ErrorMessage
                    error={error}
                    className={`!left-[-23px] !bottom-[-27px] ${classes.errorClassName}`}
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      />
    );
  };

export default forwardRef(ImageDropZone);
