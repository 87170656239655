
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useAddComparison } from 'lib/hooks/mutations/Finances/useAddComparison';

export interface IAddNewCostProps {
    onConfirm: () => void;
    title?: string;

}
const AddNewCost: FC<IAddNewCostProps> = ({ onConfirm, title }) => {

    const { t } = useTranslation();

    const { control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            head_section: title,
            section: 'Cost',
            cost_1: '',
            cost_2: '',
            cost_3: '',
        }
    });

    const { mutateAsync, isLoading } = useAddComparison()

    const onSubmit = (data: any) => {

        
        mutateAsync(data).then(() => {
            onConfirm();
        })
    };

    


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-5">

                
               
                <Controller
                    name='cost_1'
                    control={control}
                    rules={{
                        required: `${t('Direct cost is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Direct cost must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Direct Cost')}
                            placeholder={t('Enter direct cost')}
                            error={errors?.cost_1}

                        />
                    )}
                />
                <Controller
                    name='cost_2'
                    control={control}
                    rules={{
                        required: `${t('Indirect cost is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Indirect cost must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Indirect Cost')}
                            placeholder={t('Enter indirect cost')}
                            error={errors?.cost_2}
                        />
                    )}
                />

                <Controller
                    name='cost_3'
                    control={control}
                    rules={{
                        required: `${t('Employee Commission is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Employee Commission must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Employee Commission')}
                            placeholder={t('Enter employee commission')}
                            error={errors?.cost_3}
                        />
                    )}
                />


            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="" type='submit' icon={<Icon iconType={IconType.PLUS_ICON} />} disabled={isLoading}>
                    {t(`Add new cost`)}
                </IconButton>
            </div>
        </Form >
    )
}

export default withModal(AddNewCost)