import { addComparison } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useAddComparison = () => {
    const queryClient = useQueryClient();
    return useMutation(addComparison, {
        onSuccess: () => {
            toast.success("Added sucesfully");
            queryClient.invalidateQueries('comparison');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}