import FinancesView from 'components/organisms/finances/FinancesView'
import React, { FC } from 'react'


export interface IFinancesProps { }


const Finances: FC<IFinancesProps> = () => {
    return (
        <FinancesView />
    )
}

export default Finances