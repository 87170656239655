import { FC } from "react";
import withModal from "lib/helpers/hoc/withModal";
import { useTranslation } from "react-i18next";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Label from "components/atoms/input/input-label/InputLabel";
import { Controller, useForm } from "react-hook-form";
import RadioButton from "components/atoms/radio-button/RadioButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Input from "components/atoms/input/Input";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import { useEndContract } from "lib/hooks/mutations/Employee/useEndContract";

interface Props {
    onConfirm: () => void;
    user_id: number;
}

const EndContract: FC<Props> = ({
    onConfirm,
    user_id
}) => {
    const { t } = useTranslation();

    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
        reset,
        control
    } = useForm({
        defaultValues: {
            user_id: user_id,
            termination_side: 'Our side',
            contract_ended_on: null,
            reason_contract_ended: null,
            other_type: '',
            file: null
        }
    });

    const { mutateAsync, isLoading } = useEndContract()

    const EndContractSubmit = (data: any) => {
        const finalData = {
            ...data,
            reason_contract_ended: data.reason_contract_ended === 'Add other' ? data.other_type : data.reason_contract_ended
        }
        const formData = new FormData();

        Object.keys(finalData).forEach(key => {
            if (data[key] !== null) {
                formData.append(key, data[key]);
            }
        });

        mutateAsync(formData).then(() => {
            onConfirm();
            reset();
        });
    }

    return (
        <Form className="mb-0 flex flex-col gap-5" onSubmit={handleSubmit(EndContractSubmit)}>
            <div>
                <Label>
                    Termination Side
                </Label>
                <div className='grid grid-cols-2 gap-3'>
                    <Controller
                        name='termination_side'
                        control={control}
                        render={({ field }) => (
                            <RadioButton
                                label={t('Our side')}
                                isChecked={field.value === 'Our side'}
                                value={'Our side'}
                                onChange={() => field.onChange('Our side')}
                                labelClassName="h-[41px] items-center flex bg-white"
                                iconWrapperClassName="!top-[20px] !right-[6px]"
                                className="right-[8px]"
                            />
                        )}
                    />

                    <Controller
                        name='termination_side'
                        control={control}
                        render={({ field }) => (
                            <RadioButton
                                label={t('Employee side')}
                                isChecked={field.value === 'Employee side'}
                                value={'Employee side'}
                                onChange={() => field.onChange('Employee side')}
                                labelClassName="h-[41px] items-center flex bg-white"
                                iconWrapperClassName="!top-[20px] !right-[6px]"
                                className="right-[8px]"
                            />
                        )}
                    />

                </div>
            </div>
            <Controller
                control={control}
                name="contract_ended_on"
                rules={{ required: `${t("This field is required")}` }}
                render={({ field: { onChange, value } }) => (
                    <PrimaryDatePicker
                        label={t("Contract Ends Per")}
                        startDate={value}
                        {...register("contract_ended_on")}
                        setStartDate={(date: string) => onChange(date)}
                        error={errors.contract_ended_on}
                    />
                )}
            />
            <div className="relative">
                <Label text={t("Reason")} />
                <Controller
                    name="reason_contract_ended"
                    control={control}
                    rules={{ required: `${t("This field is required")}` }}
                    render={({ field }) => (
                        <CreatableSelect<any, false>
                            {...field}
                            placeholder={t("Choose termination reason")}
                            options={

                                watch('termination_side') === 'Our side'
                                    ? [
                                        { value: "Mutual Agreement", label: t("Mutual Agreement") },
                                        { value: "Breach of Contract", label: t("Breach of Contract") },
                                        { value: "Completion of Contract", label: t("Completion of Contract") },
                                        { value: "Impossibility of Performance", label: t("Impossibility of Performance") },
                                        { value: "Fraud or Misrepresentation", label: t("Fraud or Misrepresentation") },
                                        { value: "Violation of Law", label: t("Violation of Law") },
                                        { value: "Bankruptcy or Insolvency", label: t("Bankruptcy or Insolvency") },
                                        { value: "Failure of Condition Precedent", label: t("Failure of Condition Precedent") },
                                        { value: "Revocation", label: t("Revocation") },
                                        { value: "Termination Clause", label: t("Termination Clause") },
                                        { value: "Mutual Mistake", label: t("Mutual Mistake") },
                                        { value: "Contract Rescission", label: t("Contract Rescission") },
                                        { value: "Non-renewal", label: t("Non-renewal") },
                                        { value: "Add other", label: t("Add other") },

                                    ]
                                    : [
                                        { value: "Mutual Agreement", label: t("Mutual Agreement") },
                                        { value: "Better Job Opportunity", label: t("Better Job Opportunity") },
                                        { value: "Career Change", label: t("Career Change") },
                                        { value: "Personal Reasons", label: t("Personal Reasons") },
                                        { value: "Health Issues", label: t("Health Issues") },
                                        { value: "Family Obligations", label: t("Family Obligations") },
                                        { value: "Relocation", label: t("Relocation") },
                                        { value: "Dissatisfaction with Job Role", label: t("Dissatisfaction with Job Role") },
                                        { value: "Lack of Career Advancement", label: t("Lack of Career Advancement") },
                                        { value: "Inadequate Compensation", label: t("Inadequate Compensation") },
                                        { value: "Poor Work Environment", label: t("Poor Work Environment") },
                                        { value: "Conflict with Management or Colleagues", label: t("Conflict with Management or Colleagues") },
                                        { value: "Lack of Recognition or Appreciation", label: t("Lack of Recognition or Appreciation") },
                                        { value: "Add other", label: t("Add other") },

                                    ]
                            }
                            value={
                                field?.value
                                    ? {
                                        value: field?.value,
                                        label: t(field?.value),
                                    }
                                    : null
                            }
                            className="cursor-pointer font-inter-regular react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                field.onChange(selectedValue);
                            }}
                            styles={customStyles}
                            isValidNewOption={() => false}
                            menuPosition={"fixed"}
                            menuPortalTarget={document.body}
                        />
                    )}
                />
                {errors.reason_contract_ended?.message && (
                    <>
                        <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px] left-[2px]">
                            <>{errors.reason_contract_ended?.message}</>
                        </div>
                    </>
                )}
            </div>
            {watch("reason_contract_ended") === "Add other" && (
                <Controller
                    name="other_type"
                    control={control}
                    rules={{ required: `${t("This field is required")}` }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t("Reason")}
                            placeholder={t("Choose termination reason")}
                            error={errors?.other_type}
                        />
                    )}
                />
            )}
            <div>

                <Controller
                    control={control}
                    name="file"
                    render={({ field: { onChange, value } }) => (
                        <FileDropZone
                            key={`file-dropzone-`}
                            file={value}
                            setFile={onChange}
                            label=""
                            classes={{ label: "bg-white" }}
                            name="file"
                            error={errors.file}
                            control={control as any}
                        />
                    )}
                />
            </div>
            <div className="pt-5 flex justify-end gap-3">
                <IconButton secondary className="md:min-w-[120px]" type="button" onClick={() => { reset() }} disabled={isLoading}>
                    {t('Reset')}
                </IconButton>
                <IconButton disabled={isLoading}>
                    {t('Continue Termination')}
                </IconButton>
            </div>
        </Form>
    );
};

export default withModal(EndContract);