import { memo } from "react";
interface Props {
  className?: string;
}
const EmployeeJobIcon = ({ className }: Props) => {
  return (
    <svg className={className} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 0.00390625H3C2.20435 0.00390625 1.44129 0.319977 0.87868 0.882586C0.316071 1.4452 0 2.20826 0 3.00391V13.0039C0 13.7996 0.316071 14.5626 0.87868 15.1252C1.44129 15.6878 2.20435 16.0039 3 16.0039H17C17.7956 16.0039 18.5587 15.6878 19.1213 15.1252C19.6839 14.5626 20 13.7996 20 13.0039V3.00391C20 2.20826 19.6839 1.4452 19.1213 0.882586C18.5587 0.319977 17.7956 0.00390625 17 0.00390625ZM3 2.00391H17C17.2652 2.00391 17.5196 2.10926 17.7071 2.2968C17.8946 2.48434 18 2.73869 18 3.00391L10 7.88391L2 3.00391C2 2.73869 2.10536 2.48434 2.29289 2.2968C2.48043 2.10926 2.73478 2.00391 3 2.00391ZM18 13.0039C18 13.2691 17.8946 13.5235 17.7071 13.711C17.5196 13.8986 17.2652 14.0039 17 14.0039H3C2.73478 14.0039 2.48043 13.8986 2.29289 13.711C2.10536 13.5235 2 13.2691 2 13.0039V5.28391L9.48 9.85391C9.63202 9.94168 9.80446 9.98788 9.98 9.98788C10.1555 9.98788 10.328 9.94168 10.48 9.85391L18 5.28391V13.0039Z" fill="#7D8592" />
    </svg>

  );
};

export default memo(EmployeeJobIcon);
