

import { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

export interface IDashboardBoxTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    title: string;
}

const DashboardBoxTitle: FC<IDashboardBoxTitleProps> = ({ title, ...rest }) => {
    const { t } = useTranslation();
    return (
        <div className={`font-inter-semibold text-primaryTextColor sm:text-[17px] md:text-[17px] lg:text-[24px] ${rest.className}`}>{t(title)}</div>
    )
}

export default DashboardBoxTitle