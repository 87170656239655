import Input from 'components/atoms/input/Input'
import Label from 'components/atoms/input/input-label/InputLabel'
import RadioButton from 'components/atoms/radio-button/RadioButton'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


interface Props {
  control: any;
  errors: any;
}


const Step1FixedSalary: FC<Props> = ({ control, errors }) => {

  const { t } = useTranslation()

  return (
    <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name='name'
          rules={{ required: t('This field is required') }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label='Salary Module Name'
              placeholder={t('Enter salary module name')}
              error={errors.name}
            />
          )}
        />
        <Controller
          name='base_salary'
          rules={{
            required: t('This field is required'),
            pattern: { value: /^[0-9]+$/, message: "Please enter only numbers" }
          }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label='Base Salary (Annual)'
              placeholder={t('Enter base salary (Annual)')}
              error={errors.base_salary}
            />
          )}
        />
        <div>
          <Label>
            13th Month Salary
          </Label>
          <div className='grid grid-cols-2 gap-3'>
            <Controller
              name='th13_salary'
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t('Yes')}
                  isChecked={field.value === 'Yes'}
                  value={'Yes'}
                  onChange={() => field.onChange('Yes')}
                  labelClassName="h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />

            <Controller
              name='th13_salary'
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t('No')}
                  isChecked={field.value === 'No'}
                  value={'No'}
                  onChange={() => field.onChange('No')}
                  labelClassName="h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1FixedSalary