import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

// Import the variable pie module from Highcharts



export const ExternalCostsChart = () => {

    const {t} = useTranslation()

    const options = {
        chart: {
            type: 'column',
            className: 'external-costs-chart',
            spacingBottom: 0,           // Remove extra spacing at the bottom
        },
        title: {
            text: null
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true,
            gridLineColor: '#DDD',
            gridLineDashStyle: 'Dash',
            labels: {
                style: {
                    fontFamily: 'InterRegular',
                    fontSize: '12px',
                    color: '#282D46'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: null
            },
            gridLineColor: '#DDD',
            gridLineDashStyle: 'Dash',
            labels: {
                style: {
                    fontFamily: 'InterRegular',
                    fontSize: '12px',
                    color: '#282D46'
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 15,
                borderRadius: 100
            }
        },
        series: [
            {
                name: 'Others',
                data: [150, 200, 300, 400, 250, 320, 300, 280, 350, 370],
                color: '#F29425'
            },
            {
                name: 'Appointments',
                data: [50, 70, 80, 100, 90, 100, 110, 90, 95, 80,],
                color: '#A601FF'
            },
            {
                name: 'Leads',
                data: [20, 30, 40, 35, 25, 30, 25, 20, 30, 45,],
                color: '#50B8E7'
            }
        ],
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        }
    };



    return (
        <>
            <div className='flex gap-3 mb-7'>
                <div className="flex-none flex gap-2 items-center">
                    <div className='w-[15px] h-[15px] rounded-[5px] bg-[#50B8E7]' />
                    <div className='text-primaryTextColor text-xs font-inter-regular'>
                        {t('Leads')}
                    </div>
                </div>
                <div className="flex-none flex gap-2 items-center">
                    <div className='w-[15px] h-[15px] rounded-[5px] bg-[#F29425]' />
                    <div className='text-primaryTextColor text-xs font-inter-regular'>
                        {t('Appointments')}
                    </div>
                </div>
                <div className="flex-none flex gap-2 items-center">
                    <div className='w-[15px] h-[15px] rounded-[5px] bg-[#A601FF]' />
                    <div className='text-primaryTextColor text-xs font-inter-regular'>
                        {t('Others')}
                    </div>
                </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    )
};