import Input from 'components/atoms/input/Input';
import Label from 'components/atoms/input/input-label/InputLabel';
import { customStyles } from 'lib/helpers/configs/customStyles';
import React, { FC } from 'react'
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

interface Props {
  control: any;
  errors: any;
}

const Step4Bonus: FC<Props> = ({ control, errors }) => {

  const { t } = useTranslation()

  return (
    <div className='rounded-[20px] bg-[#F6FBFE] p-4 mt-5'>
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name='bonus_amount'
          rules={{ required: t('This field is required') }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label='Bonus Amount'
              placeholder={t('Enter bonus amount')}
              error={errors.bonus_amount}
            />
          )}
        />
        <div className="relative">
          <Label text={t('Bonus Trigger')} />
          <Controller
            name="bonus_trigger"
            rules={{ required: t('This field is required') }}
            control={control}
            render={({ field }) => (
              <CreatableSelect<any, false>
                {...field}
                placeholder={t('Choose the bonus trigger')}
                options={[
                  { value: "100 Health insurance contracts", label: t("100 Health insurance contracts") },
                  { value: "200 Car insurance contracts", label: t("200 Car insurance contracts") },
                  { value: "500 VVG contracts", label: t("500 VVG contracts") },
                  { value: "10 000 CHF Revenue Threshold", label: t("10 000 CHF Revenue Threshold") },
                ]}
                value={
                  field?.value
                    ? {
                      value: field?.value,
                      label: t(field?.value)
                    }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
          {errors.bonus_trigger?.message && (
            <>
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                <>{errors.bonus_trigger?.message}</>
              </div>
            </>
          )}
        </div>

      </div>
    </div>
  )
}

export default Step4Bonus