import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getCommisions } from "api/Finances";


export const useCommissions = (search: string) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['commissions', search],
        () => {
            return getCommisions({
                search: search,
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
