import { deleteExternalInternalCost } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useDeleteExternalInternalCost = (isExternal: boolean) => {
    const queryClient = useQueryClient();
    return useMutation(deleteExternalInternalCost, {
        onSuccess: () => {
            toast.success("Deleted sucesfully");
            queryClient.invalidateQueries(isExternal ? 'externalCosts' : 'internalCosts');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}