
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import Label from 'components/atoms/input/input-label/InputLabel';
import { customStyles } from 'lib/helpers/configs/customStyles';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Creatable from "react-select/creatable";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useAddAnalysis } from 'lib/hooks/mutations/Finances/useAddAnalysis';

export interface IAddNewAnalysisModalProps {
    onConfirm: () => void;
    title?: string;

}
const AddNewAnalysisModal: FC<IAddNewAnalysisModalProps> = ({ onConfirm, title }) => {

    const { t } = useTranslation();

    const { data: partnersData } = usePartners();

    const { control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: {
            head_section: title?.split(' ')[0],
            partner: null,
            other_partner: '',
            direct_cost: '',
            indirect_cost: '',
            revenue: '',
        }
    });

    const { mutateAsync, isLoading } = useAddAnalysis()

    const onSubmit = (data: any) => {

        const finalData = {
            ...data,
            partner: data.partner === 'Add other' ? data.other_partner : data.partner
        }
        mutateAsync(finalData).then(() => {
            onConfirm();
        })
    };

    const partners = useMemo(() => partnersData?.partners ? [...partnersData?.partners] : [], [partnersData]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-5">

                <div className="relative">
                    <Label text={t('Company')} />
                    <Controller
                        name="partner"
                        control={control}
                        rules={{ required: `${t('Company is required')}` }}
                        render={({ field }) => (
                            <Creatable<any, false>
                                {...field}
                                placeholder={t('Choose Company')}
                                options={[...[
                                    ...partners,
                                    { name: 'Add other', id: 'Add other' },
                                ].map((user: any) => ({
                                    label: t(user?.name),
                                    value: user?.name,
                                }))]}

                                value={
                                    field?.value
                                        ? {
                                            value: field?.value,
                                            label: t(field?.value),
                                        }
                                        : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption
                                        ? selectedOption.value
                                        : null;
                                    field.onChange(selectedValue);

                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                            />
                        )}
                    />
                    {errors.partner?.message && (
                        <>
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                <>{errors.partner?.message}</>
                            </div>
                        </>
                    )}
                </div>
                {
                    watch('partner') === 'Add other' && (
                        <Controller
                            name='other_partner'
                            control={control}
                            rules={{
                                required: `${t('Company is required')}`
                            }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    label={t('Company')}
                                    placeholder={t('Enter company name')}
                                    error={errors?.other_partner}
                                />
                            )}
                        />
                    )
                }
                <Controller
                    name='direct_cost'
                    control={control}
                    rules={{
                        required: `${t('Direct cost is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Direct cost must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Direct Cost')}
                            placeholder={t('Enter direct cost')}
                            error={errors?.direct_cost}

                        />
                    )}
                />
                <Controller
                    name='indirect_cost'
                    control={control}
                    rules={{
                        required: `${t('Indirect cost is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Indirect cost must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Indirect Cost')}
                            placeholder={t('Enter indirect cost')}
                            error={errors?.indirect_cost}
                        />
                    )}
                />

                <Controller
                    name='revenue'
                    control={control}
                    rules={{
                        required: `${t('Revenue is required')}`,
                        pattern: { value: /^[0-9]+$/, message: `${t('Revenue must be a number')}` }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('Revenue')}
                            placeholder={t('Enter the revenue')}
                            error={errors?.revenue}
                        />
                    )}
                />


            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="" type='submit' icon={<Icon iconType={IconType.PLUS_ICON} />} disabled={isLoading}>
                    {t(`Add new ${title?.toLowerCase()}`)}
                </IconButton>
            </div>
        </Form >
    )
}

export default withModal(AddNewAnalysisModal)