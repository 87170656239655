import React, { FC } from 'react'
import Icon from '../../atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useTranslation } from 'react-i18next';


export interface IUploadedFileProps {
    title: string;
    icon: IconType;
    size: string;
    updatedate?: string;
    link?: string;
    isEditable?: boolean;
    dropdown?: React.ReactElement;
    linkName?: string;
    deleteIcon?: React.ReactElement;
}


const UploadedFile: FC<IUploadedFileProps> = ({
    title,
    icon,
    size,
    updatedate,
    link,
    isEditable = true,
    deleteIcon,
    linkName
}) => {


    const openPreviewFile = () => {
        window.open(
            `https://devmainhub.insusales.com/api/${linkName}/document/${link}`
        )
    }
    const { t } = useTranslation();

    return (
        <div className='bg-white border borber-border px-4 lg:px-6 py-4 rounded-[8px]'>
            <div className="flex flex-row gap-5 lg:gap-10 items-center flex-wrap justify-between">
                <div className="flex-none">
                    {link ?
                        <div onClick={openPreviewFile} className='cursor-pointer'>
                            <Icon iconType={icon} color="#50B8E7" />
                        </div>
                        :
                        <div>
                            <Icon iconType={icon} color="#50B8E7" />
                        </div>
                    }

                </div>
                <div className="flex-1">

                    {link ?
                        <div onClick={openPreviewFile} className='cursor-pointer'>
                            <div className='font-inter-medium text-sm text-primaryTextColor'>

                                <span>{title}</span>
                            </div>
                        </div>
                        :
                        <div className='font-inter-medium text-sm text-primaryTextColor'>

                            <span>{title}</span>
                        </div>
                    }
                    <div className='text-sm font-inter-regular text-secondaryTextColor'>
                        <span>{size}</span>
                    </div>
                </div>
                {
                    updatedate && (
                        <div className="flex-none">
                            <span className='text-xs font-inter-medium text-secondaryTextColor'>{t('Created at')} {updatedate}</span>
                        </div>
                    )
                }
                {
                    (isEditable && deleteIcon) && (
                        <div className="flex-none relative">
                            {deleteIcon}
                        </div>
                    )
                }

            </div>
        </div >
    )
}

export default UploadedFile