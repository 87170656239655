import Icon from "components/atoms/icon/Icon";

import { IconType } from "../iconTypes";
import ToggleSwitchComponent from "components/molecules/toggle-switch/ToggleSwitch";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ReactDOM from "react-dom";
import { useUpdateCampaignStatus } from "lib/hooks/mutations/Campaign/useUpdateCampaignStatus";
import AdditionalInfoProcess from "components/organisms/campaign/create-campaign/additional-info-process/AdditionalInfoProcess";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const campaignHeaders = [
  {
    id: 1,
    Header: "Name",
    accessor: "name",
    isChecked: true
  },
  {
    id: 2,
    Header: "Total Leads",
    accessor: "leads_count",
    isChecked: true
  },
  {
    id: 6,
    Header: "Created By",
    accessor: "created_by",
    isChecked: true
  },
  {
    id: 3,
    Header: "Assigned to",
    accessor: "assigned_to",
    isChecked: true
  },
  {
    id: 4,
    Header: "Status",
    accessor: "status",
    Cell: ({ row }: any) => {
      return (
        <ToggleSwitchComp
          id={row.original.id}
          value={row.original.status}
          original={row.original}
        />
      );
    },
    isChecked: true
  },
  {
    Header: "",
    accessor: "actions",
    id: 5,
    Cell: ({ row }: any) => (
      <ActionButton
        id={row.original.id}
        value={row.original.status}
        original={row.original}
      />
    ),
    isChecked: true
  }
];

export const ActionButton = ({ original, id }: any) => {
  const [ThreeDotsOpen, setThreeDotsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<any>(null);
  const { themeColors } = useThemeContext();
  const dropdownRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasAdditinalUserDetails, setHasAdditinalUserDetails] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 235;
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.right + window.scrollX - dropdownWidth
      });
    }
    setThreeDotsOpen(!ThreeDotsOpen);
  };

  const hasPermissionToCreateCampaign = usePermissions(['campaign_management_create']);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ThreeDotsOpen &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setThreeDotsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ThreeDotsOpen]);

  return (
    <div className="flex flex-row items-center w-fit">
      <div className="relative">
        <button
          ref={buttonRef}
          type="button"
          className="pl-5"
          onClick={handleClick}
        >
          <Icon iconType={IconType.OPTIONS_ICON} />
        </button>
        {ThreeDotsOpen &&
          ReactDOM.createPortal(
            <div
              style={{
                position: "absolute",
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`
              }}
              ref={dropdownRef}
            >
              <ConditionalRender condition={hasPermissionToCreateCampaign}>
              <div className="shadow-custom py-[15px] px-[21px]  w-[230px]  bg-white flex flex-col text-[14px] gap-1 font-inter-medium rounded-[12px]">
                <div>
                  <div className="flex flex-row items-center cursor-pointer">
                    <div>
                      <Icon
                        iconType={IconType.INCRASE_ICON}
                        color={themeColors.primary}
                      />
                    </div>
                    <div
                      className="px-4 text-[14px] font-inter-medium whitespace-nowrap"
                      onClick={() => {
                        setIsModalOpen(true);
                        setHasAdditinalUserDetails(original);
                      }}
                    >
                      Additional File Upload
                    </div>
                  </div>
                </div>
              </div>
              </ConditionalRender>
            </div>,
            document.body
          )}
      </div>
      <ConditionalRender condition={isModalOpen}>
        <AdditionalInfoProcess
          clearData={true}
          currentStep={currentStep}
          data={hasAdditinalUserDetails}
          setCurrentStep={setCurrentStep}
          hocClasses={{
            modalClassName:
              currentStep === 1
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : currentStep === 2
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : currentStep === 3
                ? "w-full md:md:max-w-[700px] lg:min-w-[1008px] max-w-[1008px]"
                : currentStep === 4
                ? "w-full md:max-w-[700px]  lg:min-w-[762px] max-w-[762px]"
                : "",
            titleClassName: currentStep === 1 ? "" : "ml-[35px]"
          }}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
          hocTitle={
            currentStep === 1
              ? "Upload CSV"
              : currentStep === 2
              ? "Upload CSV"
            : currentStep === 3
              ? "Upload CSV"
              : ""
          }
          themeColors={themeColors}
          hocCurrentStep={currentStep}
          hocToggleModal={
            isModalOpen
              ? () => {
                  setIsModalOpen(false);
                }
              : () => {
                  setIsModalOpen(true);
                }
          }
          hocisOpen={isModalOpen}
          setHocCurrentStep={setCurrentStep}
          hocHasChanges={false}
        />
      </ConditionalRender>
    </div>
  );
};

export const ToggleSwitchComp = ({ id, value, original }: any) => {
  const [IsChecked, setIsChecked] = useState(value === 1);
  const { mutateAsync: updateStatus } = useUpdateCampaignStatus();

  React.useEffect(() => {
    setIsChecked(value === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const toggleStatus = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    updateStatus({ campagne_id: id })
      .then((res: any) => {
        if (res.status !== undefined) {
          setIsChecked(res.status === 1); // Assuming res.status being 1 means "checked"
        }
      })
      .catch(() => {});
  };

  return (
    <div>
      <ToggleSwitchComponent
        checked={IsChecked}
        initialValue={"" as any}
        onChange={toggleStatus}
        classes={{ label: "!mb-0" }}
      />
    </div>
  );
};
