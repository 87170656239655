import Button from 'components/atoms/button/Button'
import Icon from 'components/atoms/icon/Icon'
import Input from 'components/atoms/input/Input'
import Accordion from 'components/molecules/accordion/Accordion'
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody'
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle'
import Tooltip from 'components/molecules/tooltip/Tooltip'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useHover } from 'lib/hooks/shared/useHover'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'components/molecules/table/Table'
import IconButton from 'components/atoms/button/IconButton'

interface Props {
  data: any
  setFormCurrentStep: (step: number) => void
  setPermissionData: (item: any) => void
}
const Step1RoleInfo: FC<Props> = ({ setFormCurrentStep, setPermissionData, data }) => {

  const { t } = useTranslation()



  const { isHovered: isHoveredDetails, onMouseEnter: onMouseEnterDetails, onMouseLeave: onMouseLeaveDetails } = useHover();

  const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const TableHeaders = [
    {
      id: 1,
      Header: `${t('Functionalities')}`,
      accessor: (row: any) =>
        row?.created_at,
      Cell: ({ row }: any) => (
        <div>
          {row.original.name}
        </div>
      ),
      isChecked: true
    },
    {
      id: 2,
      Header: `${t('Last Updated')}`,
      accessor: (row: any) =>
        row?.created_at,
      Cell: ({ row }: any) => (
        <div>
          {new Date(row.original?.updated_at).toLocaleDateString('de')}
        </div>
      ),
      isChecked: true
    },
    {
      id: 3,
      Header: `${t('Access Rights')}`,
      accessor: (row: any) =>
        row?.created_at,
      Cell: ({ row }: any) => (
        <div className='flex gap-3'>
          {row.original?.permission.map((item: any, index: number) => (
            item.is_active === 'Yes' && (
              item.name.split('_').length > 1 && (
                <div className='py-1 px-3 bg-[#F5F6F7] rounded-2xl capitalize' key={index}>
                  {item.name.split('_')[item.name.split('_').length - 1]}
                </div>
              )
            )
          ))}
        </div>
      ),
      isChecked: true
    },
  ]

  const handleEdit = (item: any) => {
    setFormCurrentStep(2)
    setPermissionData((prev: any) => ({ ...prev, ...item }))
  }


  return (
    <div className='space-y-4'>
      <div>
        <div className='pb-3'>
          <div className="flex items-center gap-2">
            <span className='font-inter-semibold text-primaryTextColor'>{t("Role Details")}</span>
            <div className="relative leading-none" onMouseLeave={onMouseLeaveDetails}>
              <Button onMouseEnter={onMouseEnterDetails}>
                <Icon iconType={IconType.TOOLTIP_INFO_ICON} className='cursor-pointer' />
              </Button>
              <Tooltip
                isOpen={isHoveredDetails}
                className="absolute right-0 w-[250px] lg:w-[450px] h-auto bg-white p-[10px] rounded-[8px] shadow-[0_6px_58px_0px_rgba(121,145,173,0.2)] top-[0px] left-[30px] !z-[99999]"

                content={
                  <ul className="list-disc ml-4 font-inter-medium text-[#667085] text-xs">
                    <li>
                      {t('Role Details include the Role Name, Start Date, and End Date.')}
                    </li>
                    <li>
                      {t("You can update the user's role by specifying the end date of their current role.")}
                    </li>
                    <li>
                      {t('All previous roles are archived, allowing you to view them easily.')}
                    </li>
                  </ul>
                }
              />

            </div>
          </div>
        </div>
        <div className='flex gap-5 lg:gap-6 p-4 bg-[#F6FBFE] rounded-3xl'>
          <Input
            label='Role Name'
            placeholder='Enter role name'
            classes={{ container: 'flex-1' }}
            value={(data as any)?.role.name}
            disabled
          />
          <Input
            label='Creation Date'
            placeholder=''
            classes={{ container: 'flex-1' }}
            value={new Date((data as any)?.role.created_at).toLocaleDateString('de')}
            disabled
          />
          <Input
            label='Updated At'
            placeholder=''
            classes={{ container: 'flex-1' }}
            value={new Date((data as any)?.role.updated_at).toLocaleDateString('de')}
            disabled
          />
        </div>
      </div>
      <div>
        <div className='pb-3'>
          <div className="flex items-center gap-2">
            <span className='font-inter-semibold text-primaryTextColor'>{t("Role Permissions")}</span>
            <div className="relative leading-none" onMouseLeave={onMouseLeave}>
              <Button onMouseEnter={onMouseEnter}>
                <Icon iconType={IconType.TOOLTIP_INFO_ICON} className='cursor-pointer' />
              </Button>
              <Tooltip
                isOpen={isHovered}
                className="absolute right-0 w-[250px] lg:w-[550px] h-auto bg-white p-[10px] rounded-[8px] shadow-[0_6px_58px_0px_rgba(121,145,173,0.2)] top-[0px] left-[30px] !z-[99999]"
                content={
                  <ul className="list-disc ml-4 font-inter-medium text-[#667085] text-xs">
                    <li>
                      {t('Role Permissions include all modules and their associated functionalities that the user can access.')}
                    </li>
                    <li>
                      {t("You can easily view the last updated date for each functionality and the user's access rights.")}
                    </li>
                    <li>
                      {t('Additional functionalities can be added or edited at any time.')}
                    </li>
                  </ul>
                }
              />

            </div>
          </div>
        </div>
        <div className='space-y-4'>

          {(data as any).data?.map((item: any) => (
            <Accordion classes={{ wrapper: '!rounded-2xl !p-0 !border-[#A2A1A833]', title: '!bg-[#F6FBFE] !px-4 !py-3 !rounded-2xl', body: '!px-4 !mt-0' }} key={item.module_id}>
              <AccordionTitle subTitleClass={'font-inter-semibold text-primaryTextColor'}>{item.module.name}</AccordionTitle>
              <AccordionBody>
                <div className='pb-4 mt-4 w-full'>
                  <Table columns={TableHeaders} data={item?.parent_permission || []} search={""} isColumnSorted={true} classes={{ headerClasses: '!border-b-0' }} />
                  <div className='flex justify-end'>
                    <div className='cursor-pointer' onClick={() => { handleEdit(item) }}>
                      <IconButton className='w-full max-w-[150px]' icon={<Icon iconType={IconType.EDIT_ICON} color='white' className='w-[18px] h-[18px]' />} >
                        Edit
                      </IconButton>
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
          ))}

        </div>
      </div>
    </div>
  )
}

export default Step1RoleInfo