import { editRolePermissions } from "api/Employee";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useEditRolePermissions = () => {
    const queryClient = useQueryClient();
    return useMutation(editRolePermissions, {
        onSuccess: () => {
            toast.success("Updated successfully!");
            queryClient.invalidateQueries('rolesOfCompany')

            

        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}