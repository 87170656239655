import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import {
  ISideNavLinks,
  sideNavLinks,
} from "lib/helpers/constants/sideNavLinks";
import { useLogout } from "lib/hooks/mutations/Logout/useLogout";
import { useActiveLink } from "lib/hooks/shared/useActiveLink";
import { FC, useState } from "react";
import NavItem from "components/atoms/nav-item/NavItem";
import { IVerifyToken } from "lib/types/user.types";
import SideNavItem from "./SidenavItem";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useSidebarContext } from "lib/context/SidebarContext/SidebarContext";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { useTranslation } from "react-i18next";

export interface ISidenavProps {}

const Sidenav: FC<ISidenavProps> = () => {
  const { mutate: logout } = useLogout();
  const location = window.location.pathname;
  const { handleActive } = useActiveLink(location);
  const [showDropDown, setShowDropDown] = useState<string | null>(null);
  const token: string | unknown = localStorage.getItem("token");
  const { setCurrentStep } = useMultiStepFormContext();
  const { themeColors } = useThemeContext();
  const { user } = useAuthContext();

  const { isToggled, toggleSidebar } = useSidebarContext();

  const handleDropdown = (event: React.MouseEvent, id: string) => {
    event.preventDefault();
    setShowDropDown(showDropDown === id ? null : id);
  };

  const renderDropDownMenu = (item: ISideNavLinks) => {
    if (item.children && showDropDown === item.id) {
      return (
        <ul
          className={`flex flex-col gap-1 3xl:gap-[5px] ${
            isToggled ? "ml-12" : "ml-[11px] 2xl:ml-[15px]"
          } transition-all duration-200 ease-in-out pb-2 pt-2 nth-of-type(2):p-[3px]`}
        >
          {item.children.map((item: ISideNavLinks) => {
            const isActiveChildren = location === item.path;
            return (
              <NavItem
                item={item}
                isActiveChildren={isActiveChildren}
                key={`children-key-${item.id}`}
                isToggled={!isToggled}
                icon={item.icon as IconType}
              />
            );
          })}
        </ul>
      );
    }
    return null;
  };

  const {t} = useTranslation();
  return (
    <div
      className={`hidden bg-white sticky top-[25px]  w-full ${
        isToggled
          ? " max-w-[200px] min-w-[220px] "
          : "max-w-[60px] min-w-[60px] "
      } transition-all duration-200 ease-in-out rounded-[20px] min-w-[220px] shadow-customsecondary  md:flex flex-col py-5 h-[calc(100vh_-_50px)] `}
    >
      <div
        className={`bg-white rounded-xl cursor-pointer transition-all duration-200 ease-in-out shadow-customsecondary p-2 border-[1px] border-[#C4CBD6] absolute right-[-13px] top-[90px] ${
          isToggled ? "!top-[65px]" : "top-[87px]"
        } w-[38px] h-[34px] flex justify-center items-center`}
        onClick={toggleSidebar}
      >
        <Icon
          iconType={
            isToggled ? IconType.LEFT_ARROW_ICON : IconType.RIGHT_ARROW_ICON
          }
          color={isToggled ? themeColors.primary : themeColors.primary}
        />
      </div>
      <img
        src={`https://devmainhub.insusales.com/api/company-logo/${user.company.logo}`}
        alt=""
        className={` transition-all duration-200 ease-in-out object-contain ${
          isToggled ? " w-[150px] h-[80px] " : "w-[50px] h-[40px]"
        } my-5 mx-auto flex-none`}
      />
      <div className="mt-[10px]  flex flex-col gap-[0px] max-h-[100%] overflow-x-hidden verticalOverflowScrollStyle">
        {sideNavLinks.map((item) => {
          const isActive = location.includes(item.path);
          return (
            <SideNavItem
              key={`side-nav-key-${item.id}`}
              item={item}
              isToggled={!isToggled}
              isActive={isActive}
              showDropDown={showDropDown}
              handleDropdown={handleDropdown}
              themeColors={themeColors}
              handleActive={handleActive}
              renderDropDownMenu={renderDropDownMenu}
            />
          );
        })}
      </div>
      <div className="mt-auto">
        <Button
          style={{
            backgroundColor: themeColors.primary,
          }}
          className={`flex items-center gap-3 transition-all duration-200 ease-in-out text-white font-inter-bold bg-[--theme] rounded-xl p-3 mt-[20px]  ${
            isToggled ? " w-[129px] m-auto" : "w-[40px] mx-auto"
          }  text-sm 3xl:text-base`}
        >
          <Icon iconType={IconType.SUPPORT_ICON} color="white" />
          {isToggled ? "Support" : ""}
        </Button>
        <Button
          className={`flex items-center gap-3 transition-all duration-200 ease-in-out font-inter-bold text-[#7D8592] mt-[30px] text-sm 3xl:text-base ${
            isToggled ? "px-4" : "mx-auto"
          } `}
          onClick={() => {
            logout(token as IVerifyToken);
            setCurrentStep(1);
          }}
        >
          <Icon iconType={IconType.LOGOUT_ICON} />
          {isToggled ? t("Logout") : ""}
        </Button>
      </div>
    </div>
  );
};

export default Sidenav;
