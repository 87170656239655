import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface IEmployeeCardsProps {
    
}

const EmployeeCards: FC<IEmployeeCardsProps> = () => {
    const { themeColors } = useThemeContext();
    const { t } = useTranslation();



    const cardsData = [
        {
            title: `${t('Open On-Boardings')}`,
            color: themeColors.primary,
            count: 125,
            icon: IconType.OPEN_ON_BOARDING_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,
            percentage: 10
        },
        {
            title: `${t('Open Off-Boardings')}`,
            color: themeColors.primary,
            count: 65,
            icon: IconType.OPEN_OFF_BOARDING_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,
            percentage: 10
        },
        {
            title: `${t('Active Employees')}`,
            count: 15,
            color: themeColors.primary,
            icon: IconType.ACTIVE_EMPLOYEE_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentagedown: IconType?.DOWN_PERCENTAGE_ICON,
            percentage: 10
        },
        {
            title: `${t('Inactive Employee')}`,
            count: 7,
            color: themeColors.primary,
            icon: IconType.INACTIVE_EMPLOYEE_ICON,
            updatedate: new Date().toLocaleDateString('de'),
            percentageup: IconType?.UP_PERCENTAGE_ICON,
            percentage: 10
        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5">
            {cardsData?.map(
                (
                    item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
                    index: number
                ) => (
                    <Card<IInfoCardProps>
                        cardType={CardType.INFO_CARD}
                        {...item}
                        key={`finances-cards-key-${index}`}
                    />
                )
            )}
        </div>
    );
};

export default EmployeeCards;
