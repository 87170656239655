import { apiRequest } from "./Api";

export const getOverview = (data?: any) =>
    apiRequest<any, any>("GET", `finance/overview`, null, undefined, data);

export const getOverviewCardStatistics = (data?: any) =>
    apiRequest<any, any>("GET", `finance/overview/card-statistic`, null, undefined, data);

export const addExternalInternalCost = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/overview/store", data);

export const updateExternalInternalCost = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/overview/update", data);

export const deleteExternalInternalCost = (data: any) =>
    apiRequest<any, any>(
        "DELETE",
        `finance/overview/delete/${data}`,
        data
    );

export const addSuccessSectionCompany = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/success/store", data);

export const updateSuccessSectionCompany = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/success/update", data);

export const addSuccessSection = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/success/store-section", data);

export const updateSuccessSection = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/success/update-section", data);

export const deleteSuccessSection = (data: any) =>
    apiRequest<any, any>(
        "DELETE",
        `finance/success/delete-section/${data}`,
        data
    );

export const deleteSuccessCompany = (data: any) =>
    apiRequest<any, any>(
        "DELETE",
        `finance/success/delete`,
        data
    );

export const getSuccessSections = (data?: any) =>
    apiRequest<any, any>("GET", `finance/success`, null, undefined, data);

export const getAnalysis = (data?: any) =>
    apiRequest<any, any>("GET", `finance/analysis`, null, undefined, data);

export const addNewAnalysis = (data: any) =>
    apiRequest<FormData, any>("POST", "finance/analysis/store", data);

export const updateAnalysis = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/analysis/edit`, data);

export const deleteAnalysis = (data: any) =>
    apiRequest<any, any>(
        "DELETE",
        `finance/analysis/delete/${data}`,
        data
    );

export const getComparison = (data?: any) =>
    apiRequest<any, any>("GET", `finance/comparison`, null, undefined, data);

export const addComparison = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/comparison/store`, data);

export const getCommisions = (data?: any) =>
    apiRequest<any, any>("GET", `finance/commission`, null, undefined, data);

export const addCommision = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/commission/store`, data);

export const addCommisionCompany = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/commission/store/company`, data);

export const addCommisionCompanyCommission = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/commission/store/company/commission`, data);

export const deleteCommission = (data: any) =>
    apiRequest<any, any>(
        "DELETE",
        `finance/commission/delete/company/commission/${data}`,
        data
    );

export const updateCommisionCompany = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/commission/update/company/commissions`, data);

export const getSalaries = (data?: any) =>
    apiRequest<any, any>("GET", `finance/salaries`, null, undefined, data);

export const addSalaries = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/salaries/store`, data);

export const assignSalaryToUser = (data: any) =>
    apiRequest<FormData, any>("POST", `finance/salaries/assign`, data);
