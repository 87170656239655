import React, { ComponentType, useEffect, useRef } from "react";
import Button from "components/atoms/button/Button";
import { IconType } from "../constants/iconTypes";
import Icon from "components/atoms/icon/Icon";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useTranslation } from "react-i18next";

interface IWithModalClasses {
  modalClassName: string;
  titleClassName?: string;
}

interface IWithModalProps {
  hocisOpen: boolean;
  hocToggleModal: () => void;
  hocClasses: Partial<IWithModalClasses>;
  setHocCurrentStep?: (step: number) => void;
  hocCurrentStep: number;
  hocTitle?: string;
  hocHasChanges?: boolean;
  clearData?: boolean,
  setStepOnClose?: number;
  headerComponent?: React.ReactNode;
}

const withModal = <P extends object>(Component: ComponentType<P>) => {
  return (props: P & IWithModalProps) => {
    const {
      hocisOpen,
      hocToggleModal,
      hocClasses = {
        modalClassName: "",
        titleClassName: "",
      },
      hocCurrentStep,
      setHocCurrentStep,
      hocTitle,
      hocHasChanges,
      clearData = false,
      setStepOnClose,
      ...rest
    } = props;
    const { clearFormData } = useMultiStepFormContext();
    const { t } = useTranslation();

    const handleClose = () => {
      if (hocHasChanges) {
        //if has changes opens the confirmation modal but u need always to set hocCurrentStep to 3 to open the confirmation modal
        if (setStepOnClose) {
          setHocCurrentStep && setHocCurrentStep(setStepOnClose);
        } else {

          setHocCurrentStep && setHocCurrentStep(3);
        }
      } else {
        if (clearData) {
          clearFormData();
        }
        hocToggleModal();

        if (setStepOnClose) {
          setHocCurrentStep && setHocCurrentStep(setStepOnClose);
        } else {

          setHocCurrentStep && setHocCurrentStep(1);
        }
        localStorage.removeItem('lst-apt-form-state');
      }
    };

    useEffect(() => {
      if (clearData) {
        window.onbeforeunload = () => {
          clearFormData();
        };
      }

      return () => {
        window.onbeforeunload = null;
      };
    }, [clearData, clearFormData]);


    const scrollRef = useRef<any>()

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, [hocCurrentStep]);

    return (
      <>
        {hocisOpen && (
          <div
            className={`${hocisOpen ? "block" : "hidden"} fixed z-10 inset-0`}
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
                onClick={handleClose}
              >
                <div className="absolute inset-0 bg-[--theme] opacity-[0.16]"></div>
              </div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div
                className={`${hocisOpen ? "block" : "hidden"
                  } inline-block align-bottom bg-white rounded-[24px] text-left overflow-hidden shadow-xl transform  sm:my-8 sm:align-middle sm:w-full w-full  ${props.hocClasses.modalClassName
                  }`}
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 md:p-[35px]">
                  <div className="flex gap-5 items-center pb-5">
                    <div className="flex flex-row items-center gap-3 flex-1">
                      <h3
                        className={`text-[16px] sm:text-[18px] md:text-[20px] xl:text-[22px] font-inter-bold text-[#282D46] capitalize ${hocClasses.titleClassName}`}
                        id="modal-title"
                      >
                        {t(hocTitle as string)}
                      </h3>
                      {props.headerComponent && (
                        <div className="ml-auto">
                          {props.headerComponent}
                        </div>
                      )}

                    </div>
                    <Button
                      className={`flex justify-center items-center bg-[#F4F9FD] size-[35px] md:size-[44px] rounded-[14px] flex-none`}
                      onClick={handleClose}
                    >
                      <Icon iconType={IconType.CLOSE_ICON} />
                    </Button>
                  </div>
                  <div className="max-h-[calc(100vh_-_13rem)] verticalOverflowScrollStyle" ref={scrollRef}>
                    <Component
                      {...(rest as P)}
                      isOpen={hocisOpen}
                      toggleModal={hocToggleModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
};

export default withModal;
