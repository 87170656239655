import { assignSalaryToUser } from "api/Finances";
import { useMutation } from "react-query";
import { toast } from "react-toastify";


export const useAssignSalary = () => {
    return useMutation(assignSalaryToUser, {
        onSuccess: () => {
            toast.success("Added sucesfully");
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}