import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewAnalysisModal from './modals/AddNewAnalysisModal'
import { useAnalysis } from 'lib/hooks/queries/Finances/useAnalysis'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import UpdateAnalysisModal, { IUpdateAnalysisModalProps } from './modals/UpdateAnalysisModal'
import CreatableSelect from 'react-select/creatable'
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles'
import { months } from 'lib/helpers/constants/months'

const FinanceAnalysis = () => {

    const { t } = useTranslation()

    const [FilterData, setFilterData] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

    const [IsAddModalOpen, setIsAddModalOpen] = useState<undefined | string>()
    const [IsUpdateModalOpen, setIsUpdateModalOpen] = useState<undefined | IUpdateAnalysisModalProps['data']>()

    const { data, isLoading } = useAnalysis(FilterData)

    const [UpdateModalStep, setUpdateModalStep] = useState(1)

    if (isLoading) {
        return <div className='relative h-screen'><LoadingScreen /></div>
    }

    return (
        <div>
            <div className='flex gap-4 items-center mt-[46px] mb-[33px]'>
                <Headline title={t("Analysis Cost")} className='!mb-0 !mt-0 flex-1' />

                <CreatableSelect<any, false>

                    options={[
                        { value: 2023, label: "2023" },
                        { value: 2024, label: "2024" },
                        { value: 2025, label: "2025" },
                        { value: 2026, label: "2026" },
                    ]}
                    value={
                        FilterData.year
                            ? {
                                value: FilterData.year,
                                label: FilterData.year,
                            }
                            : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                            ? selectedOption.value
                            : null;
                        setFilterData({ ...FilterData, year: selectedValue });
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />

                <CreatableSelect<any, false>
                    options={
                        months.map((month) => ({
                            value: month.value,
                            label: t(month.label),
                        }))
                    }
                    value={
                        FilterData.month
                            ? {
                                value: FilterData.month,
                                label: months[FilterData.month - 1].label,
                            }
                            : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                            ? selectedOption.value
                            : null;
                        setFilterData({ ...FilterData, month: selectedValue });
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />
            </div>
            <div className='flex gap-7'>
                <DashboardBoxLayout className='flex-1 flex flex-col gap-5'>
                    <DashboardBoxTitle title='Lead Source' className=' !text-[--theme]' />
                    {
                        (data as any).filter((section: any) => section.head_section === 'Lead')?.map((section: any) => {
                            return (
                                <div className='p-4 border border-[#F2F4F7] rounded-[20px]' key={section.id}>
                                    <div className='flex items-center gap-3 mb-3'>
                                        <div className="flex-none">
                                            {
                                                section.logo ? (
                                                    <img alt="" className='h-[50px] w-[50px] object-contain rounded-full'
                                                        src={'https://devmainhub.insusales.com/api/partners/logo/' + section.logo}
                                                    />
                                                )
                                                    : (
                                                        <div className='h-[50px] w-[50px] object-contain rounded-full bg-[#F2F4F7]'></div>
                                                    )
                                            }

                                        </div>
                                        <div className="flex-1">
                                            <span className='text-[20px] text-primaryTextColor font-inter-regular'>
                                                {section.partner}
                                            </span>
                                        </div>
                                        <div className="flex-none">
                                            <div
                                                className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                                                onClick={() => { setIsUpdateModalOpen(section) }}
                                            >
                                                <Icon iconType={IconType.EDIT_SOURCE_ICON} color='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Direct Costs")}</span>
                                            <span className='!font-inter-semibold'>{section.direct_cost} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Indirect Costs")}</span>
                                            <span className='!font-inter-semibold'>{section.indirect_cost} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Revenue")}</span>
                                            <span className='!font-inter-semibold'>{section.revenue} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className='!font-inter-bold'>+ / -</span>
                                            <span
                                                className={`!font-inter-semibold ${section.total > 0 ? 'text-[#2CC95B]' : 'text-[#F45B69]'}`}>
                                                {section.total} CHF
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <DashboardBoxLayout className='border-dashed border-2 !border-[#D8D8D8] '>
                        <div className='h-[184px] grid place-items-center cursor-pointer' onClick={() => { setIsAddModalOpen('Lead Source') }}>
                            <div className='flex flex-col gap-3 items-center'>
                                <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                                <div>
                                    <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>
                                </div>
                            </div>
                        </div>
                    </DashboardBoxLayout>
                </DashboardBoxLayout>
                <DashboardBoxLayout className='flex-1 flex flex-col gap-5'>
                    <DashboardBoxTitle title='Appointment Source' className=' !text-[--theme]' />
                    {
                        (data as any).filter((section: any) => section.head_section === 'Appointment')?.map((section: any) => {
                            return (
                                <div className='p-4 border border-[#F2F4F7] rounded-[20px]' key={section.id}>
                                    <div className='flex items-center gap-3 mb-3'>
                                        <div className="flex-none">
                                            {
                                                section.logo ? (
                                                    <img alt="" className='h-[50px] w-[50px] object-contain rounded-full'
                                                        src={'https://devmainhub.insusales.com/api/partners/logo/' + section.logo}
                                                    />
                                                )
                                                    : (
                                                        <div className='h-[50px] w-[50px] object-contain rounded-full bg-[#F2F4F7]'></div>
                                                    )
                                            }

                                        </div>
                                        <div className="flex-1">
                                            <span className='text-[20px] text-primaryTextColor font-inter-regular'>
                                                {section.partner}
                                            </span>
                                        </div>
                                        <div className="flex-none">
                                            <div
                                                className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                                                onClick={() => { setIsUpdateModalOpen(section) }}
                                            >
                                                <Icon iconType={IconType.EDIT_SOURCE_ICON} color='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Direct Costs")}</span>
                                            <span className='!font-inter-semibold'>{section.direct_cost} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Indirect Costs")}</span>
                                            <span className='!font-inter-semibold'>{section.indirect_cost} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className=''>{t("Revenue")}</span>
                                            <span className='!font-inter-semibold'>{section.revenue} CHF</span>
                                        </div>
                                        <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                                            <span className='!font-inter-bold'>+ / -</span>
                                            <span
                                                className={`!font-inter-semibold ${section.total > 0 ? 'text-[#2CC95B]' : 'text-[#F45B69]'}`}>
                                                {section.total} CHF
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <DashboardBoxLayout className='border-dashed border-2 !border-[#D8D8D8] '>
                        <div className='h-[184px] grid place-items-center cursor-pointer' onClick={() => { setIsAddModalOpen('Appointment Source') }}>
                            <div className='flex flex-col gap-3 items-center'>
                                <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                                <div>
                                    <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>
                                </div>
                            </div>
                        </div>
                    </DashboardBoxLayout>
                </DashboardBoxLayout>

            </div>
            <AddNewAnalysisModal
                hocCurrentStep={1}
                title={IsAddModalOpen}
                onConfirm={() => { setIsAddModalOpen(undefined) }}
                hocisOpen={IsAddModalOpen !== undefined}
                hocToggleModal={() => setIsAddModalOpen(undefined)}
                hocTitle={t('Add ' + IsAddModalOpen)}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />
            <UpdateAnalysisModal
                currentStep={UpdateModalStep}
                setCurrentStep={setUpdateModalStep}
                hocCurrentStep={UpdateModalStep}
                data={IsUpdateModalOpen}
                onConfirm={() => { setIsUpdateModalOpen(undefined); setUpdateModalStep(1) }}
                hocisOpen={IsUpdateModalOpen !== undefined}
                hocToggleModal={() => { setIsUpdateModalOpen(undefined); setUpdateModalStep(1) }}
                hocTitle={IsUpdateModalOpen?.partner}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />

        </div>
    )
}

export default FinanceAnalysis