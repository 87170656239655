import { FC, useState } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import withModal from "lib/helpers/hoc/withModal";
import { useTranslation } from "react-i18next";
import IconButton from "components/atoms/button/IconButton";
import Step1RoleInfo from "./steps/Step1RoleInfo";
import Step2Permissions from "./steps/Step2Permissions";
import { useEditRolePermissions } from "lib/hooks/mutations/Employee/useEditRolePermissions";
import { useRolePermissions } from "lib/hooks/queries/Employee/useRolePermissions";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { verifyToken } from "api/User";

interface Props {
  onConfirm: () => void;
  setFormCurrentStep: any;
  currentStep: number;
  data?: { id: number; name: string };
}

const EditRole: FC<Props> = ({
  onConfirm,
  currentStep,
  setFormCurrentStep,
  data,
}) => {
  const { t } = useTranslation();
  const [PermissionData, setPermissionData] = useState<any>({
    permissions_id: [],
  });

  const { data: GetData, isLoading: isLoadingGet } = useRolePermissions(
    data?.id
  );

  const { mutateAsync, isLoading } = useEditRolePermissions();

  const { setUser } = useAuthContext();

  const handleSubmit = () => {
    const finalData = {
      role_id: data?.id,
      module_id: PermissionData.module_id,
      permissions_id: PermissionData.permissions_id,
    };

    mutateAsync(finalData).then(() => {
      const userToken: any = localStorage.getItem("token");

      verifyToken(userToken).then((data) => {
        setUser(data.user);
      });

      onConfirm();
    });
  };

  if (isLoadingGet) {
    return <LoadingScreen />;
  }

  return (
    <div className="mb-0">
      {currentStep !== 1 && (
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            setFormCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }}
        >
          <Icon iconType={IconType.BACK_BUTTON_MODAL} color={"var(--theme)"} />
        </button>
      )}

      {
        {
          1: (
            <Step1RoleInfo
              data={GetData}
              setFormCurrentStep={setFormCurrentStep}
              setPermissionData={setPermissionData}
            />
          ),
          2: (
            <Step2Permissions
              PermissionData={PermissionData}
              setPermissionData={setPermissionData}
            />
          ),
        }[currentStep]
      }

      <div className="pt-5 flex justify-end gap-3">
        <IconButton
          secondary
          className="max-w-[247px] w-full"
          onClick={() => {
            currentStep === 1 ? onConfirm() : setFormCurrentStep(1);
          }}
          disabled={isLoading}
        >
          {t("Cancel")}
        </IconButton>
        <IconButton
          className="max-w-[247px] w-full"
          icon={<Icon iconType={IconType.DRAFT_ICON} color="white" />}
          onClick={currentStep === 1 ? onConfirm : handleSubmit}
          disabled={isLoading || PermissionData.permissions_id.length === 0}
        >
          {t("Save changes")}
        </IconButton>
      </div>
    </div>
  );
};

export default withModal(EditRole);
