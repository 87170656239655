import Input from 'components/atoms/input/Input'
import Label from 'components/atoms/input/input-label/InputLabel'
import RadioButton from 'components/atoms/radio-button/RadioButton'
import { customStyles } from 'lib/helpers/configs/customStyles'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'



interface Props {
  control: any;
  errors: any;
}


const Step2TargetPerformance: FC<Props> = ({ control, errors }) => {

  const { t } = useTranslation()


  return (
    <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name='target_name'
          rules={{ required: t('This field is required') }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label='Target Name'
              placeholder={t('Enter target name')}
              error={errors.target_name}
            />
          )}
        />
        <div className="relative">
          <Label text={t('Target Category')} />
          <Controller
            name="target_category"
            rules={{ required: t('This field is required') }}
            control={control}
            render={({ field }) => (
              <CreatableSelect<any, false>
                {...field}
                placeholder={t('Choose the target category')}
                options={[
                  { value: "KVG", label: t("KVG") },
                  { value: "VVG", label: t("VVG") },
                  { value: "LifeInsurance", label: t("LifeInsurance") },
                  { value: "BusinessInsurance", label: t("BusinessInsurance") },
                  { value: "TravelInsurance", label: t("TravelInsurance") },
                  { value: "CarInsurance", label: t("CarInsurance") },
                ]}
                value={
                  field?.value
                    ? {
                      value: field?.value,
                      label: t(field?.value)
                    }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
          {errors.target_category?.message && (
            <>
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                <>{errors.target_category?.message}</>
              </div>
            </>
          )}
        </div>
        <div className="relative">
          <Label text={t('Measurement Criteria')} />
          <Controller
            name="target_measurement_criteria"
            rules={{ required: t('This field is required') }}

            control={control}
            render={({ field }) => (
              <CreatableSelect<any, false>
                {...field}
                placeholder={t('Choose the measurement Criteria')}
                options={[
                  { value: "Number of Contracts", label: t("Number of Contracts") },
                  { value: "Insurance premium", label: t("Insurance premium") },
                ]}
                value={
                  field?.value
                    ? {
                      value: field?.value,
                      label: t(field?.value)
                    }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
          {errors.target_measurement_criteria?.message && (
            <>
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                <>{errors.target_measurement_criteria?.message}</>
              </div>
            </>
          )}
        </div>
        <Controller
          name='target_value'
          rules={{ required: t('This field is required'), pattern: { value: /^[0-9]+$/, message: t('Only numbers are allowed') } }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label='Target Value'
              placeholder={t('Enter target value')}
              error={errors.target_value}
            />
          )}
        />
        <div>
          <Label>
            Target Frequency
          </Label>
          <div className='grid grid-cols-2 gap-3'>
            <Controller
              name='target_frequency'
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t('Monthly')}
                  isChecked={field.value === 'Monthly'}
                  value={'Monthly'}
                  onChange={() => field.onChange('Monthly')}
                  labelClassName="h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
            <Controller
              name='target_frequency'
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t('Annually')}
                  isChecked={field.value === 'Annually'}
                  value={'Annually'}
                  onChange={() => field.onChange('Annually')}
                  labelClassName="h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step2TargetPerformance