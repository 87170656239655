import Headline from "components/atoms/headline/Headline";
import Input from "components/atoms/input/Input";
import { useTranslation } from "react-i18next";

const SalaryDetails = () => {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-5">
            <div className="border border-border rounded-[20px] p-5">
                <Headline title={t("Fixed Salary")} className="!mt-0 !mb-[15px] !text-[20px]" />
                <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
                    <Input
                        label={t("Base Salary (Annual)")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="3000 CHF"
                        disabled={true}
                    />
                    <Input
                        label={t("13th Month Salary")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Yes"
                        disabled={true}
                    />
                    <Input
                        label={t("Payment Interval (Monthly)")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="6"
                        disabled={true}
                    />
                    <Input
                        label={t("Currency")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="CHF"
                        disabled={true}
                    />

                </div>
            </div>
            <div className="border border-border rounded-[20px] p-5">
                <Headline title={t("Target Component")} className="!mt-0 !mb-[15px] !text-[20px]" />
                <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
                    <Input
                        label={t("Target Category")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Sales Figures"
                        disabled={true}
                    />
                    <Input
                        label={t("Time Frame")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Monthly"
                        disabled={true}
                    />
                    <Input
                        label={t("Measurement Criteria")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Car Insurance"
                        disabled={true}
                    />
                    <Input
                        label={t("Target Description")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Make 15 Contract Per Month"
                        disabled={true}
                    />

                </div>
            </div>
            <div className="border border-border rounded-[20px] p-5">
                <Headline title={t("Commission Component")} className="!mt-0 !mb-[15px] !text-[20px]" />
                <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
                    <Input
                        label={t("Commision Basis")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Per Client"
                        disabled={true}
                    />
                    <Input
                        label={t("Commission Rate")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="5%"
                        disabled={true}
                    />
                    <Input
                        label={t("Payment Time")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Contract signed and confirmed"
                        disabled={true}
                    />
                    <Input
                        label={t("Retention")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Client diclined"
                        disabled={true}
                    />

                </div>
            </div>
            <div className="border border-border rounded-[20px] p-5">
                <Headline title={t("Bonus Component")} className="!mt-0 !mb-[15px] !text-[20px]" />
                <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
                    <Input
                        label={t("Bonus Type")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Performance Bonus"
                        disabled={true}
                    />
                    <Input
                        label={t("Calculation Basis")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Company Profit"
                        disabled={true}
                    />
                    <Input
                        label={t("Payment Conditions")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Confirmed all rules"
                        disabled={true}
                    />
                    <Input
                        label={t("Bonus Interval")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="Quarterly"
                        disabled={true}
                    />
                    <Input
                        label={t("Bonus Amount")}
                        classes={{
                            label:
                                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                        value="2000 CHF"
                        disabled={true}
                    />

                </div>
            </div>
        </div>
    );
};

export default SalaryDetails;
