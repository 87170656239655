import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getOverview } from "api/Finances";


interface FiltersProps {
    filter_date: { year: number, month: number };
}
export const useExternalCosts = (
    filter_date: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['externalCosts',filter_date],
        () => {
            return getOverview({
                type_of_overview: 'External',
                filter_date: filter_date?.filter_date.year + '-' + filter_date?.filter_date.month,
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
