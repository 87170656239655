import { deleteSuccessCompany } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useDeleteSuccessCompany = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteSuccessCompany, {
        onSuccess: () => {
            toast.success("Deleted sucesfully");
            queryClient.invalidateQueries('monthlySuccess');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}