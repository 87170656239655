import { updateAnalysis } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useEditAnalysis = () => {
    const queryClient = useQueryClient();
    return useMutation(updateAnalysis, {
        onSuccess: () => {
            toast.success("Updated sucesfully");
            queryClient.invalidateQueries('analysisCost');
        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}