import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function LeaderboardThirdIcon({ className, color }: Props) {
    return (
        <svg width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6172 4.28125L9.87586 19.7049L22.1187 14.4646L10.6172 4.28125Z" fill="#D5874C" fillOpacity="0.944124"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.51828 13.8424L22.0781 14.3731L9.83529 19.6134L2.51828 13.8424Z" fill="#D5874C" fillOpacity="0.7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.7541 5.45784L9.83426 19.6138L22.0771 14.3735L22.7541 5.45784Z" fill="#D5874C" fillOpacity="0.7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4571 4.12463C10.9744 3.90322 11.2142 3.30441 10.9928 2.78715C10.7714 2.26989 10.1726 2.03005 9.65534 2.25145C9.13807 2.47286 8.89823 3.07167 9.11964 3.58893C9.34105 4.10619 9.93985 4.34603 10.4571 4.12463Z" fill="#D5874C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.1472 5.21663C23.7031 5.30298 24.2239 4.92226 24.3102 4.36627C24.3965 3.81028 24.0158 3.28956 23.4598 3.20322C22.9038 3.11687 22.3831 3.49759 22.2968 4.05358C22.2104 4.60957 22.5912 5.13029 23.1472 5.21663Z" fill="#D5874C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.56121 14.7518C2.1172 14.8381 2.63792 14.4574 2.72426 13.9014C2.81061 13.3454 2.42989 12.8247 1.8739 12.7384C1.31791 12.652 0.797191 13.0328 0.710847 13.5887C0.624502 14.1447 1.00522 14.6654 1.56121 14.7518Z" fill="#D5874C"/>
        <circle cx="22.0078" cy="29" r="14.5" fill="white" stroke="#D5874C"/>
        <path d="M22.0053 35.1577C21.1871 35.1577 20.4599 35.0175 19.8235 34.7372C19.1909 34.4569 18.6909 34.0668 18.3235 33.5668C17.9561 33.0668 17.761 32.4891 17.7383 31.8338H19.8746C19.8936 32.1482 19.9978 32.4228 20.1871 32.6577C20.3765 32.8887 20.6284 33.0687 20.9428 33.1974C21.2572 33.3262 21.6095 33.3906 21.9996 33.3906C22.4163 33.3906 22.7856 33.3187 23.1076 33.1747C23.4296 33.027 23.6815 32.8224 23.8633 32.5611C24.0451 32.2997 24.1341 31.9986 24.1303 31.6577C24.1341 31.3054 24.0432 30.9948 23.8576 30.7259C23.672 30.4569 23.4031 30.2467 23.0508 30.0952C22.7023 29.9437 22.2818 29.8679 21.7894 29.8679H20.761V28.2429H21.7894C22.1947 28.2429 22.5489 28.1728 22.8519 28.0327C23.1587 27.8925 23.3993 27.6955 23.5735 27.4418C23.7478 27.1842 23.833 26.8868 23.8292 26.5497C23.833 26.2202 23.7591 25.9342 23.6076 25.6918C23.4599 25.4455 23.2496 25.2543 22.9769 25.1179C22.708 24.9815 22.3917 24.9134 22.0281 24.9134C21.672 24.9134 21.3424 24.9777 21.0394 25.1065C20.7364 25.2353 20.4921 25.419 20.3065 25.6577C20.1209 25.8925 20.0224 26.1728 20.011 26.4986H17.9826C17.9978 25.8471 18.1853 25.2751 18.5451 24.7827C18.9087 24.2865 19.3936 23.9001 19.9996 23.6236C20.6057 23.3433 21.2856 23.2031 22.0394 23.2031C22.8159 23.2031 23.4902 23.349 24.0621 23.6406C24.6379 23.9285 25.083 24.3168 25.3974 24.8054C25.7118 25.294 25.869 25.8338 25.869 26.4247C25.8728 27.08 25.6796 27.6293 25.2894 28.0724C24.9031 28.5156 24.3955 28.8054 23.7667 28.9418V29.0327C24.5849 29.1463 25.2118 29.4493 25.6474 29.9418C26.0868 30.4304 26.3046 31.0384 26.3008 31.7656C26.3008 32.4171 26.1152 33.0005 25.744 33.5156C25.3765 34.027 24.869 34.4285 24.2212 34.7202C23.5773 35.0118 22.8387 35.1577 22.0053 35.1577Z" fill="#282D46"/>
        </svg>
        
    );
}
export default memo(LeaderboardThirdIcon);
