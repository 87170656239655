import IconButton from "components/atoms/button/IconButton";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC, useState } from "react";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import ConfigureTableDropdown from "components/organisms/leads/dropdowns/ConfigureTableDropdown";
import PendingsGridView from "../pendings-grid-view/PendingsGridView";
import PendingsList from "../pendings-list/PendingsList";
import CreatePending from "../create-pendings/CreatePending";
import UpdatePendingsProcess from "../update-pendings-process/UpdatePendingsProcess";
import { useCustomerPendings } from "lib/hooks/queries/Pendings/useCustomerPendings";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import { usePending } from "lib/hooks/queries/Pendings/usePending";
import { toast } from "react-toastify";
import { format } from "date-fns";
import CustomerFilterTable from "../filters/CustomerFilterTable";
import { useTranslation } from "react-i18next";

export interface IPendingsViewProps {}

const CustomersTable: FC<IPendingsViewProps> = () => {
  const [isCreatePendingModalOpen, setIsCreatePendingModalOpen] =
    useState<boolean>(false);
  const [currentPending, setCurrentPending] = useState<number>(1);
  const [isUpdatePendingModalOpen, setIsUpdatePendingModalOpen] =
    useState<boolean>(false);
  const [currentUpdatePending, setCurrentUpdatePending] = useState<number>(1);
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>({
    filter_search: "",
    status: "",
    deadline: "",
    created_at: "",
  });
  const {t} = useTranslation();
  const [activeView, setActiveView] = useState<string>("listView");
  const [id, setId] = useState<number>();
  const {
    data: userData,
    isError,
    isLoading: isLoadingFetchingUser,
  } = usePending(id);

  const handleUpdatePending = (id: number) => {
    if (!isError) {
      setId(id);
      setIsUpdatePendingModalOpen(true);
    } else {
      toast.error(`Something went wrong`);
    }
  };
  const {
    data,
    isLoading,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    itemsPerPage,
  } = useCustomerPendings(
    filterData?.filter_search ?? "",
    filterData?.status ?? "",
    filterData?.deadline ?? "",
    filterData?.created_at ?? ""
  );

  const columns = [
    {
      id: 1,
      Header: `${t("Creation Date")}`,
      accessor: "deadline",
      Cell: ({ row: { original } }: any) => {
        return (
          <p
            className="text-[14px] font-inter-medium text-[#282D46] cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            {original?.created_at
              ? format(new Date(original.created_at), "MM/dd/yyyy")
              : "No Available"}{" "}
          </p>
        );
      },
      isChecked: true,
    },
    {
      id: 2,
      Header: `${t("Deadline")}`,
      accessor: "deadline",
      Cell: ({ row: { original } }: any) => {
        return (
          <p
            className="text-[14px] font-inter-medium text-[#282D46] cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            {original?.deadline ?? `${t("No Available")}`}
          </p>
        );
      },
      isChecked: true,
    },
    {
      id: 3,
      Header: `${t("Category")}`,
      accessor: "category",
      Cell: ({ row: { original } }: any) => {
        return (
          <p
            className="text-[14px] font-inter-medium text-[#282D46] cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            {original?.category ?? `${t("No Available")}`}
          </p>
        );
      },
      isChecked: true,
    },
    {
      id: 4,
      Header: `${t("Pending Title")}`,
      accessor: "title",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex gap-1 items-center cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <h1 className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.costumer?.first_name} {original?.costumer?.last_name}
            </h1>
            <p className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.title}
            </p>
          </div>
        );
      },
      isChecked: true,
    },
    {
      id: 5,
      Header: `${t("Consultant")}`,
      accessor: "from_user",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex gap-1 items-center cursor-pointer"
            onClick={() => {
              handleUpdatePending(original.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <h1 className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.costumer?.from_user} 
            </h1>
          </div>
        );
      },
      isChecked: true,
    },
    {
      id: 6,
      Header: `${t("Status")}`,
      accessor: "status",
      Cell: ({ row: { original } }: any) => {
        const status = original?.status ?? `${t("Pending Sales")}`;
        return (
          <p
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
            className={`min-w-[50px] cursor-pointer w-[max-content] h-[24px] p-3 text-[12px] font-inter-medium flex items-center justify-center rounded-[4px] capitalize ${
              status === "Done"
                ? "bg-[#2DE28B] bg-opacity-[10%] text-[#2DE28B]"
                : status === "Processing"
                ? "bg-[#50B8E7] bg-opacity-[10%] text-[#50B8E7]"
                : status === "Pending Sales" || status === "Pending Backoffice"
                ? "bg-[#EFBE12] bg-opacity-[10%] text-[#EFBE12]"
                : status === "Pending"
                ? "bg-[#F88532] bg-opacity-[10%] text-[#F88532]"
                : ""
            }`}
          >
            {t(status)}
          </p>
        );
      },
      isChecked: true,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex items-center gap-[3px] ml-auto justify-end cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <Icon iconType={IconType.SHOW_PASSWORD_ICON} />
            <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
          </div>
        );
      },
      isChecked: true,
    },
  ];
  const [cardsOrder, setCardsOrder] = useState(columns);

  return (
    <div>
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`${t('Pendings')}`}
            />
          </div>
          <IconButton
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
          >
            {t("New Pending")}
          </IconButton>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search by (Title)")}
              value={filterData?.filter_search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  filter_search: e.target.value,
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title={t("Configure Table")}
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title={t("Filter")}
                    className="sm:!w-[350px]"
                  >
                    <CustomerFilterTable
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={activeView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={activeView === "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Customer Pendings" />}
            >
              <PendingsGridView data={data?.pages?.[0]?.data?.data ?? []} />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
        <ConditionalRender condition={activeView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Customer Pendings" />}
            >
              <PendingsList
                data={data?.pages?.[0]?.data?.data ?? []}
                dataCols={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <PaginationControls
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={(value: number) =>
          handleItemsPerPageChange(value)
        }
        from={data?.pages[0]?.data?.from}
        to={data?.pages[0]?.data?.to}
        total={data?.pages[0]?.data?.total}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
      <ConditionalRender condition={currentPending === 1}>
        <CreatePending
          currentPending={currentPending}
          hocClasses={{
            modalClassName: currentPending === 1 ? "md:w-[551px]" : "",
          }}
          hocCurrentStep={currentPending}
          hocToggleModal={() =>
            setIsCreatePendingModalOpen(!isCreatePendingModalOpen)
          }
          hocisOpen={isCreatePendingModalOpen}
          setHocCurrentStep={setCurrentPending}
          clearData={true}
          hocTitle={t("Create New Pending")}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUpdatePendingModalOpen}>
        <UpdatePendingsProcess
          showToCommentsBackButton={true}
          showToCommentsConfirm={true}
          userData={userData}
          setCurrentUpdatePending={setCurrentUpdatePending}
          updateCurrentPending={currentUpdatePending}
          isLoadingFetchingUser={isLoadingFetchingUser}
          hocClasses={{
            modalClassName:
              currentUpdatePending === 1
                ? "sm:w-[600px] md:max-w-[600px] sm-md:max-w-[600px] lg:min-w-[973px] 2xl:w-[948px]"
                : currentUpdatePending === 2
                ? "sm:max-w-[467px]   md:min-w-[467px] lg:w-[467px]"
                : currentUpdatePending === 3
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px]   lg:min-w-[894px]"
                : currentUpdatePending === 4
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] lg:min-w-[894px]"
                : currentUpdatePending === 5
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] 2xl:min-w-[930px]"
                : ""
          }}
          hocCurrentStep={currentUpdatePending}
          hocToggleModal={() => setIsUpdatePendingModalOpen(false)}
          hocisOpen={isUpdatePendingModalOpen}
          setIsUpdatePendingModalOpen={setIsUpdatePendingModalOpen}
          setHocCurrentStep={setCurrentUpdatePending}
          clearData={true}
          hocTitle={
            currentUpdatePending === 1
              ? ""
              : currentUpdatePending === 2
              ? `${t("Upload File")}`
              : ""
          }
        />
      </ConditionalRender>
    </div>
  );
};

export default CustomersTable;
