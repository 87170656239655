import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useLeadsStatisticCard } from "lib/hooks/queries/Leads/useLeadsStatisticCard";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface ILeadsCardsProps {
  hasOwn?: string;
}

const LeadsCards: FC<ILeadsCardsProps> = ({ hasOwn }) => {
  const {themeColors} = useThemeContext();
  const {t} = useTranslation();
  const {
    data
  } = useLeadsStatisticCard(hasOwn);

  const cardsData = [
    {
      title: `${t('Total Leads')}`,
      color: themeColors.primary,
      count: data?.total_leads,
      icon: IconType.TOTAL_EVENTS_ICON,
      updatedate: new Date().toLocaleDateString('de'),
      percentageup:
      data?.won_percent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      
    },
    {
      title: `${t('Converted Leads')}`,
      color: themeColors.primary,
      count: data?.converted,
      icon: IconType.PENDING_ICON,
      updatedate: new Date().toLocaleDateString('de'),
   
    },
    {
      title: `${t('Lost Leads')}`,
      count: data?.lost,
      color: themeColors.primary,
      icon: IconType.LOST_LEADS_ICON,
      updatedate: new Date().toLocaleDateString('de'),
    
    },
    {
      title: `${t('Conversion Rate')}`,
      count: data?.conversionRate,
      color: themeColors.primary,
      icon: IconType.CONVERSION_RATE_ICON,
      updatedate: new Date().toLocaleDateString('de'),

    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5">
      {cardsData?.map(
        (
          item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
          index: number
        ) => (
          <Card<IInfoCardProps>
            cardType={CardType.INFO_CARD}
            {...item}
            key={`leads-cards-key-${index}`}
          />
        )
      )}
    </div>
  );
};

export default LeadsCards;
