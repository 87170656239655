import { updateExternalInternalCost } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateExternalInternalCost = (type?: string) => {
    const queryClient = useQueryClient();
    return useMutation(updateExternalInternalCost, {
        onSuccess: () => {
            toast.success("Cost added sucesfully");
            queryClient.invalidateQueries(type ? 'internalCosts' : 'externalCosts');
            queryClient.invalidateQueries('costsCardStatistics');

        },
        onError: () => {
            toast.error("Something went wrong! Please try again later.");
        },
    })
}