import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import React from "react";
import { useTranslation } from "react-i18next";

interface EmptyModuleProps {
    title: string;
}

const EmptyModule: React.FC<EmptyModuleProps> = ({
    title,
}) => {

    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center gap-7 py-20 px-5">
            <Icon iconType={IconType.EMPTY_DOCUMENT_ICON} className="max-w-[165px] w-full h-auto" />
            <div className="text-center text-[#7D8592] text-xs font-inter-regular">
                <p>{t(title)} {t("haven't been added yet.")}</p>
                <div>
                    {t("Tap 'Add New' to include details.")}
                </div>
            </div>
        </div>
    );
};

export default EmptyModule;
