
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import Label from 'components/atoms/input/input-label/InputLabel';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from 'lib/helpers/configs/customStyles';
import { usePartners } from 'lib/hooks/queries/Partners/usePartnersForFilter';
import RangeSlider from 'components/atoms/range-slider/RangeSlider';
import { useAddCommisionCompanyCommission } from 'lib/hooks/mutations/Finances/useAddCommisionCompanyCommission';



export interface IAddNewCommissionCompanyProps {
    onConfirm: () => void;
    id?: string;
}

const AddNewCommissionCompany: FC<IAddNewCommissionCompanyProps> = ({ onConfirm, id }) => {

    const { t } = useTranslation();

    const { control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            finance_company_id: id,
            partner_id: null,
            company_commission: 0
        }
    });


    const { data: partnersData } = usePartners();

    const { mutateAsync, isLoading } = useAddCommisionCompanyCommission()

    const onSubmit = (data: any) => {
        mutateAsync(data).then(() => {
            onConfirm()
        })
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-5">

                <div className="relative">
                    <Label text={t('Company')} />
                    <Controller
                        name="partner_id"
                        control={control}
                        rules={{ required: `${t('Company is required')}` }}
                        render={({ field }) => (
                            <CreatableSelect<any, false>
                                {...field}
                                placeholder={t('Choose company')}
                                options={
                                    (
                                        partnersData?.partners as unknown as Array<{
                                            name: string;
                                            id: string;
                                        }>
                                    )?.map?.((user) => ({
                                        label: user?.name,
                                        value: user?.id,
                                    })) ?? []
                                }
                                value={
                                    field?.value
                                        ? {
                                            value: field?.value,
                                            label:
                                                (
                                                    partnersData?.partners?.find?.(
                                                        (user: any) => user.id === field.value
                                                    ) as { name: string } | undefined
                                                )?.name || field.value,
                                        }
                                        : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption
                                        ? selectedOption.value
                                        : null;
                                    field.onChange(selectedValue);

                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                            />
                        )}
                    />
                    {errors.partner_id?.message && (
                        <>
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                <>{errors.partner_id?.message}</>
                            </div>
                        </>
                    )}
                </div>
                <Controller
                    name="company_commission"
                    control={control}
                    rules={{ required: `${t('Company is required')}` }}
                    render={({ field }) => (
                        <RangeSlider
                            value={field.value}
                            onChange={field.onChange}
                            rightLabel='Salesmen'
                            leftLabel='Company Profit'
                        />
                    )}
                />

            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="" type='submit' icon={<Icon iconType={IconType.PLUS_ICON} />} disabled={isLoading}>
                    {t('Add commission')}
                </IconButton>
            </div>
        </Form >
    )
}

export default withModal(AddNewCommissionCompany)