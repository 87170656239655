import { memo } from "react";
interface Props {
    className?: string;
    color?: string
}
const DeleteWarningIcon = ({ className, color }: Props) => {
    return (

        <svg className={className} width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="64" height="64" rx="32" fill="#F2A356" />
            <path d="M32.5 44C39.1274 44 44.5 38.6274 44.5 32C44.5 25.3726 39.1274 20 32.5 20C25.8726 20 20.5 25.3726 20.5 32C20.5 38.6274 25.8726 44 32.5 44Z" stroke="white" strokeWidth="2" />
            <path d="M32.5007 40.6693C32.8688 40.6693 33.1673 40.3708 33.1673 40.0026C33.1673 39.6344 32.8688 39.3359 32.5007 39.3359C32.1325 39.3359 31.834 39.6344 31.834 40.0026C31.834 40.3708 32.1325 40.6693 32.5007 40.6693Z" fill="white" stroke="white" />
            <path d="M32.5 37.3359V35.4439C32.5 34.1853 33.3053 33.0679 34.5 32.6693C35.0823 32.4751 35.5888 32.1027 35.9478 31.6047C36.3067 31.1067 36.4999 30.5085 36.5 29.8946V29.2106C36.5 27.0693 34.7667 25.3359 32.6253 25.3359H32.5C31.4391 25.3359 30.4217 25.7574 29.6716 26.5075C28.9214 27.2577 28.5 28.2751 28.5 29.3359" stroke="white" strokeWidth="2" />
        </svg>

    )
}

export default memo(DeleteWarningIcon)