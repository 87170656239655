import Headline from 'components/atoms/headline/Headline'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExternalCosts from './costs/ExternalCosts'
import InternalCosts from './costs/InternalCosts'
import FinanceCards from './cards/FinanceCards'
import { useCardStatistics } from 'lib/hooks/queries/Finances/useCardStatistics'
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles'
import CreatableSelect from 'react-select/creatable'
import { months } from 'lib/helpers/constants/months'



const FinancesView = () => {

    const { t } = useTranslation()

    const [FilterData, setFilterData] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

    const { data } = useCardStatistics(FilterData)


    return (
        <div>
            <div className='flex gap-4 items-center mt-[46px] mb-[33px]'>
                <Headline title={t("Finances")} className='!mb-0 !mt-0 flex-1' />

                <CreatableSelect<any, false>

                    options={[
                        { value: 2023, label: "2023" },
                        { value: 2024, label: "2024" },
                        { value: 2025, label: "2025" },
                        { value: 2026, label: "2026" },
                    ]}
                    value={
                        FilterData.year
                            ? {
                                value: FilterData.year,
                                label: FilterData.year,
                            }
                            : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                            ? selectedOption.value
                            : null;
                        setFilterData({ ...FilterData, year: selectedValue });
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />

                <CreatableSelect<any, false>
                    options={
                        months.map((month) => ({
                            value: month.value,
                            label: t(month.label),
                        }))
                    }
                    value={
                        FilterData.month
                            ? {
                                value: FilterData.month,
                                label: months[FilterData.month - 1].label,
                            }
                            : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                            ? selectedOption.value
                            : null;
                        setFilterData({ ...FilterData, month: selectedValue });
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />
                <div>

                </div>
            </div>
            <div className="pb-5 mt-[26px]">
                <FinanceCards stats={data as any} />
            </div>
            <div className=" flex gap-5 flex-wrap xl:flex-nowrap">

                <div className={`basis-full`}>
                    <ExternalCosts total={(data as any)?.external_cost as string} filterData={FilterData} />
                </div>
                <div className="basis-full xl:basis-2/5">
                    <InternalCosts total={(data as any)?.internal_cost as string} filterData={FilterData}  />
                </div>

            </div>
        </div>
    )
}

export default FinancesView