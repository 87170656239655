import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import {
  Dispatch,
  FC,
  Fragment,
  ReactInstance,
  RefObject,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import ActivityLogItem from "components/molecules/activity-log-item/ActivityLogItem";
import { useCustomerForm } from "lib/hooks/forms/useCustomerForm";
import withModal from "lib/helpers/hoc/withModal";
import { useUpdateCustomer } from "lib/hooks/mutations/Customers/useUpdateCustomer";
import Checkbox from "components/atoms/checkbox/Checkbox";
import { useCreateNewProduct } from "lib/hooks/mutations/Customers/useCreateNewProduct";
import { useCustomerProduct } from "lib/hooks/queries/Coustomer/useCustomerProduct";
import UpdateChooseProducts from "./update-choose-products/UpdateChooseProducts";
import UpdateCustomerDocuments from "./update-customer-documents/UpdateCustomerDocuments";
import { useCustomerDocument } from "lib/hooks/queries/Coustomer/useCustomerDocument";
import Button from "components/atoms/button/Button";
import { useStoreNewCustomerDocument } from "lib/hooks/mutations/Customers/useStoreNewCustomerDocument";
import ErrorState from "components/atoms/error-state/ErrorState";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useReactToPrint } from "react-to-print";
import { useRefetchPartnersForFilter } from "lib/hooks/queries/Partners/useRefetchPartnersForFilter";
import { useCustomerLogs } from "lib/hooks/queries/Coustomer/useCustomersLogs";
import LocationIcon from "components/atoms/icon/icon-type/LocationIcon";
import { EmailIcon, PhoneIcon } from "components/atoms/icon/export";
import SendEmailAppointment from "components/organisms/appointment-management/update-appointment/update-appointment-step/SendEmailAppointment";
import CallAppointment from "components/organisms/appointment-management/update-appointment/update-appointment-step/CallAppointment";
import { useCustomerFamilyMembers } from "lib/hooks/queries/Coustomer/useCustomerFamilyMembers";
import UpdateRelatedCustomerForm from "./update-related-customer-form/UpdateRelatedCustomerForm";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { useHover } from "lib/hooks/shared/useHover";
import { languageData } from "lib/helpers/constants/languageData";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import { options } from "lib/helpers/constants/optionsOfCustomers";
import { useTranslation } from "react-i18next";

interface ActivityLogData<T> {
  data: T;
}

interface IUpdateCustomerProcessPrpos<T> {
  onConfirm: () => void;
  setIsModalOpen: any;
  setCurrentFormStep: Dispatch<number>;
  currentStep: number;
  data: T;
  themeColors: {
    primary: string;
  };
}

const UpdateCustomerProcess: FC<IUpdateCustomerProcessPrpos<any>> = ({
  currentStep,
  onConfirm,
  setCurrentFormStep,
  data,
  setIsModalOpen,
  themeColors,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [fileSize, setFileSize] = useState("");
  const { mutate: updateCustomer, isLoading: updateLoading } =
    useUpdateCustomer();
  const { mutateAsync: storeNewProduct, isLoading: isLoadingStoredProduct } =
    useCreateNewProduct();
  const { data: familyMemberData, isError } = useCustomerFamilyMembers({
    costumer_id: data.id,
  });
  const {
    data: customerDocumentsData,
    refetch: refetchDocuments,
    isLoading: isLoadingGettingDocuments,
  } = useCustomerDocument(
    {
      costumer_id: data.id,
    },
    {
      enabled: currentStep === 3 ? true : false,
    }
  );

  const {
    data: activityLogData,
    refetch: refetchLogs,
    isLoading: isLoadingLogs,
  } = useCustomerLogs(
    {
      costumer_id: data.id,
    },
    {
      enabled: activeTab === 4 ? true : false,
    }
  );
  const {
    mutateAsync: storeDocument,
    isLoading: isStoringLoadingDocument,
    isError: hasErrorOnDocument,
  } = useStoreNewCustomerDocument();

  const {
    data: customerProducts,
    isLoading: isLoadingCustomerProducts,
    refetch: refetchProducts,
  } = useCustomerProduct(
    {
      costumer_id: data.id,
    },
    {
      enabled: activeTab === 2 ? true : false,
    }
  );

  const { handleSubmit: handleSubmitExtraField } = useForm();
  const [mapAddress, setMapAddress] = useState({
    postal_code: "",
    address: "",
    nr: "",
    city: "",
  });
  const { data: partnersData, refetch: refetchPartners } =
    useRefetchPartnersForFilter();
  const token: any = localStorage.getItem("token");
  const [formDataOfProducts, setFormDataOfProducts] = useState<any>({});
  const [fileLink, setFileLink] = useState<string>();
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [viewFileUrl, setViewFileUrl] = useState<string | null>(null);
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const [hasDocumentName, setHasDoucmentName] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomerForm({
    defaultValues: {
      ...data,
    },
  });

  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    reset: resetUpload,
    watch: watchUpload,
    control: controlUpload,
    formState: { errors: uploadErrors },
  } = useForm();

  const onSubmit = (formData: any) => {
    const necessaryFields = [
      "first_name",
      "last_name",
      "nationality",
      "birthdate",
      "phone",
      "address",
      "city",
      "nationality",
      "date",
      "postal_code",
      "email",
      "nr",
      "note",
      "language",
    ];
    const { id, ...restFormData } = formData;
    const updateFilter = Object.keys(restFormData).reduce(
      (acc: { [key: string]: any }, key) => {
        if (necessaryFields.includes(key)) {
          acc[key] = restFormData[key];
        }
        return acc;
      },
      { costumer_id: id }
    );
    updateCustomer(updateFilter);
    onConfirm();
  };

  const handleCheckboxChange = (checkboxValue: string, checked: boolean) => {
    setFormDataOfProducts((prevFormData: any) => ({
      ...prevFormData,
      [checkboxValue]: {
        checked: checked,
        selected: checked ? prevFormData[checkboxValue]?.selected || "" : "",
      },
    }));
  };

  const {t} = useTranslation();

  const handleSelectChange = (checkboxValue: string, selected: string) => {
    setFormDataOfProducts((prevFormData: any) => ({
      ...prevFormData,
      [checkboxValue]: {
        ...prevFormData[checkboxValue],
        selected: selected,
      },
    }));
  };

  const optionsByCheckbox = [
    {
      checkboxValue: `${t('KVG')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
    {
      checkboxValue: `${t('VVG')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
    {
      checkboxValue: `${t('life')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
    {
      checkboxValue: `${t('lawprotection')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
    {
      checkboxValue: `${t('business')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
    {
      checkboxValue: `${t('auto')}`,
      customOptions: partnersData?.partners.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
      })),
    },
  ];

  const onSubmitExtraFields = () => {
    const selectedProducts = Object.keys(formDataOfProducts)
      .filter((key) => formDataOfProducts[key].checked)
      .map((key) => ({
        name_of_product: key,
        company_of_product: formDataOfProducts[key].selected,
      }));

    const updatedFormData = {
      costumer_id: data.id,
      products: selectedProducts,
    };

    storeNewProduct(updatedFormData)
      .then(() => {
        setFormDataOfProducts(updatedFormData);
        setCurrentFormStep(1);
      })
      .catch(() => {});
  };
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileName, setFileName] = useState<any>("");

  const onSubmitUploadDocument = (formData: Record<string, any>) => {
    const newFormData = new FormData();
    newFormData.append("document", uploadedFile);
    newFormData.append("costumer_id", data.id);
    for (let key in formData) {
      newFormData.append(key, formData[key]);
    }
    storeDocument(newFormData)
      .then(() => {
        setCurrentFormStep(1);
        setFileName("");
        setUploadedFile("");
        resetUpload();
        setFileSize("");
        refetchDocuments();
      })
      .catch(() => {});
  };

  const handleUploadClick = (): void => {
    const formData = {
      file_section: watchUpload("file_section"),
      file_name: watchUpload("file_name"),
    } as any;
    const newFormData = new FormData();
    newFormData.append("document", uploadedFile);
    newFormData.append("costumer_id", data.id);
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        newFormData.append(key, formData[key]);
      }
    }
    storeDocument(newFormData).then(() => {
      setCurrentFormStep(1);
      setFileName("");
      setUploadedFile("");
      resetUpload();
      setFileSize("");
      refetchDocuments();
    });
  };

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [relatedCustomers, setRelatedCustomers] = useState<any[]>([]);

  const handleAddCustomer = (customer: any) => {
    setRelatedCustomers([
      { id: customer.id, name: `${customer.first_name} ${customer.last_name}` },
    ]);
  };
  return (
    <div>
      <ConditionalRender condition={currentStep === 1}>
        <div className="flex flex-col relative lg:flex-row gap-10  !md:min-w-auto md-max-w-[750px] lg:max-w-[1200px]">
          <div className="flex-none ">
            <div className="sticky top-0">
              <div className="w-full lg:w-[250px] rounded-[10px] border border-[#A2A1A833]">
                <div
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  className={`px-6 py-4 rounded-t-[10px] flex gap-3 items-center ${
                    activeTab === 1
                      ? `text-[#fff] bg-[--theme] font-inter-semibold`
                      : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                  }`}
                >
                  <Icon
                    iconType={IconType.PROFILE_ICON}
                    color={activeTab === 1 ? "#fff" : undefined}
                  />
                  <span className="whitespace-nowrap">
                    {t('Customer Information')}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setActiveTab(2);
                    refetchProducts();
                    refetchPartners();
                  }}
                  className={`px-6 py-4 flex gap-3 items-center ${
                    activeTab === 2
                      ? `text-[#fff] bg-[--theme] font-inter-semibold`
                      : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                  }`}
                >
                  <Icon
                    iconType={IconType.PRODUCTS_ICON}
                    color={activeTab === 2 ? "#fff" : undefined}
                  />

                  <span>Products</span>
                </div>
                <div
                  onClick={() => {
                    setActiveTab(3);
                    refetchDocuments();
                  }}
                  className={`px-6 py-4 flex gap-3 items-center ${
                    activeTab === 3
                      ? `text-[#fff] bg-[--theme] font-inter-semibold`
                      : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                  }`}
                >
                  <Icon
                    iconType={IconType.DOCUMENT_ICON}
                    color={activeTab === 3 ? "#fff" : undefined}
                  />

                  <span>{t('Documents')}</span>
                </div>
                <div
                  onClick={() => {
                    setActiveTab(4);
                    refetchLogs();
                  }}
                  className={`px-6 py-4 rounded-b-[10px] flex gap-3 items-center ${
                    activeTab === 4
                      ? `text-[#fff] bg-[--theme] font-inter-semibold`
                      : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                  }`}
                >
                  <Icon
                    iconType={IconType.ACTIVITY_LOG_ICON}
                    color={activeTab === 4 ? "#fff" : undefined}
                  />

                  <span>{t('Activity Log')}</span>
                </div>
              </div>
              <ConditionalRender condition={activeTab === 1}>
                <div className="mt-[60px]">
                  <h1 className="text-[#282D46] font-inter-bold">
                    {t('Related Customers')}
                  </h1>
                  <ErrorState error={isError}>Something went wrong!</ErrorState>
                  {familyMemberData?.data?.map((item: Record<string, any>) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[--theme] h-[44px] rounded-[8px] flex items-center gap-[16px] mt-[18px] px-[10px]"
                      >
                        <Icon iconType={IconType.RELATED_CUSTOMER_ICON} />
                        <p className="font-inter-bold text-white text-[16px]">
                          {item.first_name} {item.last_name}
                        </p>
                      </div>
                    );
                  })}
                  <div>
                    <ConditionalRender
                      condition={familyMemberData?.edit_relation !== false}
                    >
                      <IconButton
                        className="!text-[--theme] bg-transparent border-none !p-0 !mt-[27px] sticky top-0"
                        secondary
                        onClick={() => {
                          setCurrentFormStep(8);
                        }}
                        icon={
                          <Icon
                            iconType={IconType.INCRASE_ICON}
                            color={themeColors.primary}
                          />
                        }
                      >
                        {t('Add new relation')}
                      </IconButton>
                    </ConditionalRender>
                    <div className="relative" onMouseLeave={onMouseLeave}>
                      <ConditionalRender
                        condition={familyMemberData?.edit_relation === false}
                      >
                        <Button onMouseEnter={onMouseEnter}>
                          <Icon
                            iconType={IconType.ALERT_ICON}
                            className="absolute right-[-10] bottom-[-10px]"
                            color={themeColors.primary}
                          />
                        </Button>
                        <Tooltip
                          isOpen={isHovered}
                          className="absolute right-0 w-[250px] h-auto bg-white p-[10px] rounded-[25px] shadow-custom  top-[30px] left-[10px] !z-[99999]"
                          content={
                            <Fragment>
                              <ul className="list-disc text-[14px]  ml-4 font-inter-regular">
                                <li>
                                  {t('Cannot update this customer relation as they are linked to an appointment.')}
                                </li>
                              </ul>
                            </Fragment>
                          }
                        />
                      </ConditionalRender>
                    </div>
                  </div>
                </div>
              </ConditionalRender>
            </div>
            <ConditionalRender condition={activeTab === 1}>
              <div className="flex flex-row gap-4 absolute right-0 top-0">
                <div
                  className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min`}
                >
                  <LocationIcon color="#fff" />
                </div>
                <div
                  className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min cursor-pointer`}
                  onClick={() => setCurrentFormStep(6)}
                >
                  <EmailIcon color="#fff" />
                </div>
                <div
                  className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min cursor-pointer`}
                  onClick={() => setCurrentFormStep(7)}
                >
                  <PhoneIcon color="#fff" />
                </div>
              </div>
            </ConditionalRender>
          </div>

          <div className="flex-1 ">
            <ConditionalRender condition={activeTab === 1}>
              <Form
                className="flex flex-col gap-5 mb-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputsFolder title={t('Personal information')}>
                  <div className="pb-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                      <Input
                        label={t('First Name')}
                        placeholder={t('First Name')}
                        {...register("first_name")}
                        error={errors?.first_name}
                      />
                      <Input
                        label={t('Last Name')}
                        placeholder={t('Last Name')}
                        {...register("last_name")}
                        error={errors?.last_name}
                      />
                      <Input
                        label={t('Nationality')}
                        placeholder={t('Nationality')}
                        {...register("nationality")}
                      />
                      <Controller
                        control={control}
                        name="birthdate"
                        rules={{ required: t("Date of birth is required") }}
                        render={({ field: { onChange, value } }) => (
                          <PrimaryDatePicker
                            label={t('Date Of Birth')}
                            startDate={value}
                            {...register("birthdate")}
                            setStartDate={(date: string) => onChange(date)}
                          />
                        )}
                      />
                      <div className="relative">
                        <Label text={t('Language')} />
                        <Controller
                          name="language"
                          control={control}
                          rules={{ required: t("Language is required") }}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              options={
                                languageData?.map?.((language) => ({
                                  value: language.value,
                                  label: language.label,
                                })) || []
                              }
                              defaultValue={
                                data?.info?.language
                                  ? {
                                      value: data.info.language,
                                      label: data.info.language,
                                    }
                                  : null
                              }
                              defaultInputValue={data?.info?.language || ""}
                              value={
                                field.value
                                  ? { value: field.value, label: field.value }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                } else if (actionMeta.action === "clear") {
                                  field.onChange("");
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        {errors.language?.message && (
                          <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                            <>{t(errors.language?.message as string)}</>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <Input
                            placeholder={t('Enter phone number')}
                            {...register("phone")}
                            error={errors.phone}
                            name="phone"
                            label={t('Phone')}
                          />
                        </div>
                        <div className="flex-none pt-[35px]">
                          <Icon iconType={IconType.PHONE_ICON} />
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <Input
                            placeholder={t('Enter email')}
                            label={t('Email')}
                            {...register("email")}
                          />
                        </div>
                        <div className="flex-none pt-[35px]">
                          <Icon iconType={IconType.EMAIL_ICON} />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="grid grid-cols-2 gap-5">
                        <Input
                          placeholder={t('Enter street')}
                          label={t('Street')}
                          icon={IconType.LOCATION_ICON}
                          {...register("address")}
                          error={errors?.address}
                          classes={{ icon: "!bg-white right-[8px]" }}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              address: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter Nr')}
                          label={t('Nr')}
                          {...register("nr")}
                          error={errors?.nr}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              nr: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter PLZ')}
                          label={t('PLZ')}
                          {...register("postal_code")}
                          error={errors?.postal_code}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              postal_code: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter City')}
                          label={t('City')}
                          {...register("city")}
                          error={errors?.city}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              city: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <iframe
                          className="border-inputborder border rounded-[8px]"
                          title="map"
                          src={`https://maps.google.com/maps?q='+${mapAddress.postal_code} ${mapAddress.address} ${mapAddress.city}  ${mapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                          style={{ width: "100%", height: "100%" }}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </InputsFolder>
                <InputsFolder
                  title={t('Related details')}
                  childrenClassName="max-h-[180px] h-full"
                >
                  <div>
                    <Label>{t('Note')}</Label>
                    <TextArea
                      {...register("note")}
                      rows={4}
                      className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                      placeholder={t('Write note')}
                      error={errors?.note}
                    />
                  </div>
                </InputsFolder>
                <IconButton className="max-w-[138px] flex justify-end ml-auto">
                  {updateLoading ? t("Updating...") : t("Update")}
                </IconButton>
              </Form>
            </ConditionalRender>
            <ConditionalRender condition={activeTab === 2}>
              <UpdateChooseProducts
                data={customerProducts ?? []}
                id={data.id}
                setFormStep={setCurrentFormStep}
                isLoading={isLoadingCustomerProducts}
                setFormDataOfProducts={setFormDataOfProducts}
                refetchProducts={refetchProducts}
                themeColors={themeColors}
              />
            </ConditionalRender>
            <ConditionalRender condition={activeTab === 3}>
              <UpdateCustomerDocuments
                onConfirm={onConfirm}
                customerData={data}
                themeColors={themeColors}
                currentStep={currentStep}
                data={customerDocumentsData}
                setCurrentFormStep={setCurrentFormStep}
                setFileLink={setFileLink}
                setHasDoucmentName={setHasDoucmentName}
                isLoadingGettingDocuments={isLoadingGettingDocuments}
              />
            </ConditionalRender>
            <ConditionalRender condition={activeTab === 4}>
              <ActivityLogItem
                data={
                  (activityLogData as { data?: any })
                    ?.data as ActivityLogData<any>
                }
                isLoading={isLoadingLogs}
                showReview={false}
                href="costumers"
              />
            </ConditionalRender>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <Form
          onSubmit={handleSubmitExtraField(onSubmitExtraFields)}
          className="pt-[30px]"
        >
          {optionsByCheckbox.map(({ checkboxValue, customOptions }) => (
            <div key={checkboxValue} className="flex items-center">
              <div className="w-[50%] flex items-center gap-5 mb-[10px]">
                <Checkbox
                  value={checkboxValue}
                  isChecked={
                    formDataOfProducts[checkboxValue] &&
                    formDataOfProducts[checkboxValue].checked
                      ? formDataOfProducts[checkboxValue].checked
                      : false
                  }
                  onChange={(e) =>
                    handleCheckboxChange(checkboxValue, e.target.checked)
                  }
                />
                <Label className="capitalize !mb-0">{checkboxValue}</Label>
              </div>
              <div className="w-[50%] mb-[10px]">
                <Controller
                  name="Company"
                  control={control}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      options={customOptions}
                      value={field.value}
                      className="cursor-pointer font-inter-regular react-select-container"
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        handleSelectChange(
                          checkboxValue,
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      styles={customStyles}
                      placeholder={t('Choose Company')}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                      isDisabled={!formDataOfProducts[checkboxValue]?.checked}
                    />
                  )}
                />
              </div>
            </div>
          ))}
          <IconButton
            type="submit"
            className="max-w-[138px] flex justify-end ml-auto mt-[30px] "
            disabled={isLoadingStoredProduct ? true : false}
          >
            {isLoadingStoredProduct ? t("Adding...") : t("Add Product")}
          </IconButton>
        </Form>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <Form
          onSubmit={handleSubmitUpload(onSubmitUploadDocument)}
          className="flex flex-col gap-[25px]"
        >
          <div className="relative">
            <Label text={t('File Section')} />
            <Controller
              name="file_section"
              control={controlUpload}
              rules={{ required: t("File Section is required") }}
              render={({ field }) => (
                <Creatable<any, false>
                  {...field}
                  placeholder={t('File Section')}
                  options={options.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  value={
                    field.value
                      ? { value: field.value, label: field.value }
                      : null
                  }
                  className="cursor-pointer font-inter-regular react-select-container"
                  classNamePrefix="react-select"
                  onChange={(newValue: any, actionMeta: any) => {
                    if (
                      actionMeta.action === "select-option" &&
                      newValue?.value
                    ) {
                      field.onChange(newValue.value);
                    }
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
            {uploadErrors.file_section?.message && (
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                <>{uploadErrors.file_section?.message}</>
              </div>
            )}
          </div>

          <Input
            placeholder={t('File Name')}
            label={t('File Name')}
            {...registerUpload("file_name")}
          />
          <Controller
            control={controlUpload}
            name="document"
            defaultValue={""}
            render={({ field: { onChange } }) => (
              <>
                <ConditionalRender condition={!fileName}>
                  <div className="text-[14px] font-inter-medium">
                    {t('Upload File')}
                  </div>
                  <div className={`flex justify-center relative`}>
                    <div className="flex items-center justify-center w-full">
                      <label
                        className={`flex flex-col-reverse cursor-pointer w-full h-[133px] border-[1px] rounded-[10px] border-border border-dashed bg-white relative`}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <div className="bg-[--theme] w-[40px] absolute top-[20%] h-[40px] rounded-[10px] flex items-center justify-center">
                            <Icon iconType={IconType.UPLOAD_FILE_ICON} />
                          </div>
                          <div className="mb-3 text-center">
                            <p className="text-primaryTextColor text-[14px] font-inter-regular mb-1">
                              {t('Drag & Drop or')}{" "}
                              <span className={`text-[--theme] underline`}>
                                {t('choose file')}
                              </span>{" "}
                              {t('to upload')}
                            </p>
                            <p className="text-[#A2A1A8] text-[11px] font-inter-regular">
                              {t('Supported formats: pdf, doc, docx, csv. Maximum size: 10 MB')}
                            </p>
                          </div>
                        </div>
                        <Input
                          type="file"
                          onChange={(e) => {
                            if (e.target?.files) {
                              const file = e.target.files[0];
                              const fileUrl = URL.createObjectURL(file);
                              setUploadedFileUrl(fileUrl);
                              setUploadedFile(file as any);
                              setFileName(file.name);
                              onChange(file);
                              if (file) {
                                const size = file.size / 1024;
                                if (size < 1024) {
                                  setFileSize(`${size.toFixed(2)} KB`);
                                } else {
                                  setFileSize(`${(size / 1024).toFixed(2)} MB`);
                                }
                              }
                            }
                          }}
                          accept={".pdf"}
                          className="!z-[-11]"
                          classes={{ container: "!z-[-22]" }}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalRender>
                <ConditionalRender condition={fileName as any}>
                  <div className="mt-2  flex flex-col gap-[25px]">
                    <div className="text-[14px] font-inter-medium">
                      {t('Uploaded File')}
                    </div>
                    <div className="flex items-center justify-between cursor-pointer">
                      <div
                        onClick={() => {
                          setCurrentFormStep(4);
                          setViewFileUrl(uploadedFileUrl);
                        }}
                        className="flex items-center gap-5"
                      >
                        <Icon iconType={IconType.PDFUPLOAD_ICON} />
                        <div>
                          <h1 className="font-inter-medium text-[#282D46] text-[14px] capitalize">
                            {fileName ?? ""}
                          </h1>
                          <p className="uppercase text-[#6C737F] text-[14px] font-inter-regular">
                            {fileSize ?? ""}
                          </p>
                        </div>
                      </div>
                      <Button
                        onClick={() => {
                          onChange(null);
                          resetUpload();
                          setUploadedFile("");
                          setFileName("");
                        }}
                      >
                        <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
                      </Button>
                    </div>
                    <ErrorState error={hasErrorOnDocument}>
                      <span>
                        {" "}
                        {t('Something went wrong')},
                        <span className="underline">{t('please try again')}</span>{" "}
                      </span>
                    </ErrorState>
                  </div>
                </ConditionalRender>
              </>
            )}
          />
          <div className="flex justify-end gap-[26px] mt-5">
            <IconButton
              type="submit"
              icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
              disabled={isStoringLoadingDocument ? true : false}
            >
              {isStoringLoadingDocument ? t("Uploading...") : t("Upload")}
            </IconButton>
          </div>
        </Form>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        {viewFileUrl && (
          <div style={{ height: "100%" }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={viewFileUrl}
                renderLoader={() => (
                  <div className="min-h-[500px]">
                    <LoadingScreen />
                  </div>
                )}
              />
            </Worker>
            <>
              <Button
                onClick={() => setCurrentFormStep(3)}
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
              >
                <Icon
                  iconType={IconType.BACK_BUTTON_MODAL}
                  className="!w-[30px] !h-[30px]"
                  color={themeColors.primary}
                />
                {t('Application')}
              </Button>
              <div className="flex justify-end gap-[26px] mt-5">
                <IconButton
                  type="submit"
                  onClick={handleUploadClick}
                  disabled={
                    isStoringLoadingDocument || hasErrorOnDocument
                      ? true
                      : false
                  }
                  icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
                >
                  {t('Upload')}
                </IconButton>
              </div>
            </>
          </div>
        )}
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 5}>
        <div>
          <div
            ref={componentRef as RefObject<HTMLDivElement>}
            style={{ height: "100%" }}
          >
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
              <Viewer
                httpHeaders={{
                  Authorization: `Bearer ${token}`,
                }}
                renderLoader={() => (
                  <div className="min-h-[500px]">
                    <LoadingScreen />
                  </div>
                )}
                fileUrl={`https://devmainhub.insusales.com/api/costumers/document/${fileLink}`}
              />
            </Worker>
          </div>
        </div>
        <>
          <Button
            onClick={() => setCurrentFormStep(1)}
            type="button"
            className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
          >
            <Icon
              iconType={IconType.BACK_BUTTON_MODAL}
              className="!w-[30px] !h-[30px]"
              color={themeColors.primary}
            />
            {t('Application')}
          </Button>
          <div className="flex justify-end gap-[26px] mt-5">
            <IconButton
              onClick={() => handlePrint()}
              type="button"
              secondary={true}
              icon={
                <Icon
                  iconType={IconType.PRINT_ICON}
                  color={themeColors.primary}
                />
              }
            >
              {t('Print')}
            </IconButton>
            <IconButton
              onClick={() => {
                fetch(
                  `https://devmainhub.insusales.com/api/costumers/document/${fileLink}`
                )
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = hasDocumentName ?? "";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                  })
                  .catch((error) => console.error(error));
              }}
              type="button"
              icon={<Icon iconType={IconType.DOWNLOAD_ICON} />}
            >
              {t('Download')}
            </IconButton>
          </div>
        </>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 6}>
        <SendEmailAppointment
          onConfirm={onConfirm}
          data={data}
          setCurrentFormStep={setCurrentFormStep}
          setIsUpdateModalOpen={setIsModalOpen}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 7}>
        <CallAppointment
          onConfirm={onConfirm}
          data={data}
          setCurrentFormStep={setCurrentFormStep}
          setIsUpdateModalOpen={setIsModalOpen}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 8}>
        <UpdateRelatedCustomerForm
          data={data}
          onAddCustomer={handleAddCustomer}
          setCurrentStep={setCurrentFormStep}
          releatedCustomerId={relatedCustomers}
        />
      </ConditionalRender>
    </div>
  );
};

export default withModal(UpdateCustomerProcess);
