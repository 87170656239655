import { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

export interface IHeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  title: string;
}

const Headline: FC<IHeadlineProps> = ({ title, ...rest }) => {
  const {t} = useTranslation();
  return (
    <h1
      className={`${rest.className} mt-[46px] text-[#282D46]  font-inter-semibold  text-[26px] md:text-[36px] mb-[33px]`}
    >
      {t(title)}
    </h1>
  );
};

export default Headline;
