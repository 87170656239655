import Headline from "components/atoms/headline/Headline";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SalaryDetails from "./SalaryDetails";

const Salary = () => {

    const { t } = useTranslation();
    const [selectedTabSwitch, setSelectedTabSwitch] = useState<number>(0);

    return (
        <div>
            <Headline title={t("Salary")} className="!mt-0 !mb-[15px]" />
            <TabsSwitch
                ActiveTab={selectedTabSwitch}
                setSelectedTab={(id) => setSelectedTabSwitch(id)}
                classes={{
                    iconAndTextWrapperClass:
                        "text-[13px] lg:text-[14px] xl:text-[16px] flex items-center",
                    text: "!pl-1 !pb-[8px]",
                    unActiveTabClass: "!pl-1 !pb-[8px] w-full",
                    tabsClass: 'gap-4'
                }}
                tabs={[
                    {
                        id: 0,
                        name: "Salary Details",
                    },
                    {
                        id: 1,
                        name: "Goals",
                    },
                    {
                        id: 2,
                        name: "Salary History",
                    },

                ]}
            />
            {
                {
                    0: <SalaryDetails />,
                    1: <div>Goals</div>,
                    2: <div>Salary History</div>,
                }[selectedTabSwitch]
            }
        </div>
    );
};

export default Salary;
