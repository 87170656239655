import React, { memo } from "react";

interface Props {
    className?: string;
}

function CommissionIcon({ className }: Props) {
    return (
        <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.695312" width="35" height="35" rx="5" fill="#50B8E7" fillOpacity="0.05" />
            <path d="M24.0287 13.3333H23.1954V12.5C23.1954 11.837 22.932 11.2011 22.4632 10.7322C21.9943 10.2634 21.3584 10 20.6954 10H12.3621C11.699 10 11.0631 10.2634 10.5943 10.7322C10.1255 11.2011 9.86206 11.837 9.86206 12.5V22.5C9.86206 23.163 10.1255 23.7989 10.5943 24.2678C11.0631 24.7366 11.699 25 12.3621 25H24.0287C24.6918 25 25.3277 24.7366 25.7965 24.2678C26.2653 23.7989 26.5287 23.163 26.5287 22.5V15.8333C26.5287 15.1703 26.2653 14.5344 25.7965 14.0656C25.3277 13.5967 24.6918 13.3333 24.0287 13.3333ZM12.3621 11.6667H20.6954C20.9164 11.6667 21.1284 11.7545 21.2846 11.9107C21.4409 12.067 21.5287 12.279 21.5287 12.5V13.3333H12.3621C12.141 13.3333 11.9291 13.2455 11.7728 13.0893C11.6165 12.933 11.5287 12.721 11.5287 12.5C11.5287 12.279 11.6165 12.067 11.7728 11.9107C11.9291 11.7545 12.141 11.6667 12.3621 11.6667ZM24.8621 20H24.0287C23.8077 20 23.5958 19.9122 23.4395 19.7559C23.2832 19.5996 23.1954 19.3877 23.1954 19.1667C23.1954 18.9457 23.2832 18.7337 23.4395 18.5774C23.5958 18.4211 23.8077 18.3333 24.0287 18.3333H24.8621V20ZM24.8621 16.6667H24.0287C23.3657 16.6667 22.7298 16.9301 22.261 17.3989C21.7921 17.8677 21.5287 18.5036 21.5287 19.1667C21.5287 19.8297 21.7921 20.4656 22.261 20.9344C22.7298 21.4033 23.3657 21.6667 24.0287 21.6667H24.8621V22.5C24.8621 22.721 24.7743 22.933 24.618 23.0893C24.4617 23.2455 24.2497 23.3333 24.0287 23.3333H12.3621C12.141 23.3333 11.9291 23.2455 11.7728 23.0893C11.6165 22.933 11.5287 22.721 11.5287 22.5V14.8583C11.7965 14.9525 12.0783 15.0004 12.3621 15H24.0287C24.2497 15 24.4617 15.0878 24.618 15.2441C24.7743 15.4004 24.8621 15.6123 24.8621 15.8333V16.6667Z" fill="#50B8E7" />
        </svg>

    );
}

export default memo(CommissionIcon);
