
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import Input from 'components/atoms/input/Input';
import { useAddCommission } from 'lib/hooks/mutations/Finances/useAddCommission';



export interface IAddNewCategoryProps {
    onConfirm: () => void;
}

const AddNewCategory: FC<IAddNewCategoryProps> = ({ onConfirm }) => {

    const { t } = useTranslation();

    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        defaultValues: {
            name: ''
        }
    });

    const { mutateAsync, isLoading } = useAddCommission()

    const onSubmit = (data: any) => {
        mutateAsync(data)
            .then(() => {
                onConfirm()
            })
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-5">
                <Input
                    label={t('Commision name')}
                    placeholder={t('Enter commision name')}
                    {...register('name')}
                    error={errors.name?.message}
                />

            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="" type='submit' icon={<Icon iconType={IconType.PLUS_ICON} />} disabled={isLoading}>
                    {t('Add commission')}
                </IconButton>
            </div>
        </Form >
    )
}

export default withModal(AddNewCategory)