import { addCommisionCompanyCommission } from "api/Finances";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useAddCommisionCompanyCommission = () => {
    const queryClient = useQueryClient();

    return useMutation(addCommisionCompanyCommission, {
        onSuccess: () => {
            toast.success("Added sucesfully");
            queryClient.invalidateQueries('commissions');
        },
        onError: (error: any) => {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            else {
                toast.error("Something went wrong! Please try again later.");
            }
        },
    })

}