import { leadToNewCustomer } from "api/Leads"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"



export const useLeadToNewCustomer = () => {
    const queryClient = useQueryClient();
    return useMutation(leadToNewCustomer, {
        onSuccess: () => {
            queryClient.invalidateQueries('leads')
            toast.success('Lead to New Customer successfully transfered')
        },
        onError: () => {
            toast.error('Somethng went wrong')
        }
    })
}