import { apiRequest } from "./Api";

export const createEmployee = (data: any) =>
  apiRequest<FormData, any>("POST", "employee/store", data);

export const getSelfProfile = (id?: string) =>
  apiRequest<any, any>("GET", `hr/user-information?id=${id}`);

export const getEmploye = (params?: any) =>
  apiRequest<any, any>("GET", "hr/users", null, undefined, params);

export const updateEmployee = (data: any) => {
  return apiRequest<FormData, any>("POST", `hr/update-user-information`, data, {
    "content-type": "multipart/form-data",
  });
};

export const showEmployeSelfDocument = (data?: any, link?: string) =>
  apiRequest<any, any>("GET", `hr/show-user-document/${link}`, data);

export const showEmployeDocument = (link?: string) =>
  apiRequest<any, any>("GET", `hr/show-user-document/${link}`);

export const getRolesOfCompany = (data?: any) =>
  apiRequest<any, any>("GET", `roles-of-company`, null, undefined, data);

export const endContract = (data: any) => {
  return apiRequest<FormData, any>("POST", `hr/end-contract`, data, {
    "content-type": "multipart/form-data",
  });
};

export const getRolePermissions = (data?: any) =>
  apiRequest<any, any>("GET", `hr/roles/role-permission`, null, undefined, data);

export const editRolePermissions = (data: any) =>
  apiRequest<FormData, any>("POST", "hr/roles/edit-role-permission", data);

