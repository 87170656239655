import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getOverviewCardStatistics } from "api/Finances";

export const useCardStatistics = (filter_date: { year: number, month: number }) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['costsCardStatistics', filter_date],
        () => {
            return getOverviewCardStatistics({
                filter_date: filter_date.year + '-' + filter_date.month,
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
