import IconButton from 'components/atoms/button/IconButton'
import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import Accordion from 'components/molecules/accordion/Accordion'
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody'
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle'
import EmptyModule from 'components/molecules/empty-module/EmptyModule'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewCommission from './modals/AddNewCommission'
import ManageCommissionModule from './modals/ManageCommissionModule'
import AddNewCategory from './modals/AddNewCategory'
import { useCommissions } from 'lib/hooks/queries/Finances/useCommissions'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import AddNewCommissionCompany from './modals/AddNewCommissionCompany'
import Input from 'components/atoms/input/Input'

const FinaceComissionView = () => {

    const { t } = useTranslation()

    const [IsAddCategoryOpen, setIsAddCategoryOpen] = useState(false)
    const [IsAddCommissionOpen, setIsAddCommissionOpen] = useState<undefined | string>()
    const [IsAddCommissionCompanyOpen, setIsAddCommissionCompanyOpen] = useState<undefined | { name: string, id: string }>()

    const [IsManageModuleOpen, setIsManageModuleOpen] = useState<undefined | any>()
    const [ManageModuleStep, setManageModuleStep] = useState(1)


    const [SearchValue, setSearchValue] = useState('')

    const { data, isLoading } = useCommissions(SearchValue)

    return (
        <div>
            <Headline title='Commission' />

            <DashboardBoxLayout>
                <div className="flex items-center justify-between mb-5">
                    <DashboardBoxTitle title='Commission Modules' />
                    <IconButton icon={<Icon iconType={IconType.PLUS_ICON} />} onClick={() => { setIsAddCategoryOpen(true) }}>
                        {t('New Commission')}
                    </IconButton>
                </div>
                <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
                    <div className="flex-1">
                        <Input
                            icon={IconType.SEARCH_ICON}
                            className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                            classes={{ icon: "left-4 !top-[30%]" }}
                            placeholder={t("Search")}

                            onChange={(e) =>
                                setSearchValue(e.target.value)
                            }
                        />
                    </div>
                    {/* <div className="flex flex-row gap-2 items-center">
                        <div className="flex-none">
                            <div className={`${filterTableOpen && "relative"}`}>
                                <FilterBorderedWrapper
                                    className="h-[48px] w-[48px] cursor-pointer"
                                    onClick={() => {
                                        setFilterTableOpen(!filterTableOpen);
                                    }}
                                >
                                    <Icon iconType={IconType.FILTER_ICON} />
                                </FilterBorderedWrapper>
                                <ConditionalRender condition={filterTableOpen}>
                                    <DynamicDropdown
                                        setIsOpen={setFilterTableOpen}
                                        showCloseButton={true}
                                        title={t('Filter')}
                                        className="sm:!w-[350px]"
                                    >
                                        <FilterTableDropdown
                                            setFilterData={setFilterData}
                                            FilterData={filterData}
                                            setIsOpen={setFilterTableOpen}
                                            isAssignedLeads={isAssignLeadsActive === 'Assigned Leads'}
                                        />
                                    </DynamicDropdown>
                                </ConditionalRender>
                            </div>
                        </div>
                        <div className="flex-none">
                            <ViewChangeButtonGroup
                                setSelectedTab={setActiveView}
                                ActiveTab={activeView}
                            />
                        </div>
                    </div> */}
                </div>
                <ConditionalRender condition={isLoading}>
                    <div className='relative py-10'>
                        <LoadingScreen />
                    </div>
                </ConditionalRender>
                <ConditionalRender condition={data?.length === 0}>
                    <EmptyModule title='Commission Modules' />
                </ConditionalRender>
                <ConditionalRender condition={data?.length !== 0}>
                    {
                        data?.map((item: any) => {
                            return (
                                <Accordion classes={{ wrapper: "!border-[#F2F4F7] p-[25px]" }} key={item.id}>
                                    <AccordionTitle subTitleClass="w-full">
                                        <div className="flex gap-4 items-center w-full relative">
                                            <Icon iconType={IconType.COMMISSION_ICON} />
                                            <div className="flex-1 text-start">
                                                <p className="font-inter-semibold text-primaryTextColor">
                                                    {item.name}
                                                </p>
                                                <span className="text-sm font-inter-regular text-[#7D8592]">
                                                    {t('Last Updated')}: {new Date(item.updated_at).toLocaleDateString("de")}
                                                </span>
                                            </div>
                                            <div className="pe-10">

                                                <IconButton onClick={() => { setIsManageModuleOpen(item) }}>{t("Manage Module")}</IconButton>
                                            </div>

                                        </div>
                                    </AccordionTitle>
                                    <AccordionBody>
                                        <div className="flex flex-col gap-5">
                                            {item.companies.map((company: any) => {
                                                return (
                                                    <div key={company.id}>
                                                        <div className="text-xs text-primaryTextColor font-inter-semibold pb-3">
                                                            {company.name}
                                                        </div>
                                                        <div className="flex gap-3 flex-wrap">
                                                            {
                                                                company.companies_commissions.map((commission: any) => {
                                                                    return (
                                                                        <div key={commission.id}
                                                                            className="flex gap-2 border border-[#F2F4F7]  px-5 py-3 rounded-[8px] items-center"
                                                                        >
                                                                            <div className="flex gap-3 items-center">
                                                                                <img alt="" className='h-[36px] w-[36px] object-contain rounded-full'
                                                                                    src={'https://devmainhub.insusales.com/api/partners/logo/' + commission.partner.logo}
                                                                                />
                                                                                <span className="text-sm text-primaryTextColor font-inter-regular">
                                                                                    {commission.partner.name}
                                                                                </span>
                                                                                <div className='ps-10 flex flex-col justify-between gap-3'>
                                                                                    <span className="text-sm text-primaryTextColor font-inter-regular">
                                                                                        {t('Company')}  {commission.company_commission}%
                                                                                    </span>
                                                                                    <span className="text-sm text-primaryTextColor font-inter-regular">
                                                                                        {t('Salesmen')}  {100 - commission.company_commission}%
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                            <div
                                                                className="w-[44px] rounded-[8px] h-[78px] border border-dashed border-[#F2F4F7] flex items-center justify-center cursor-pointer"
                                                                onClick={() => {
                                                                    setIsAddCommissionCompanyOpen({ name: company.name as string, id: company.id })
                                                                }}
                                                            >
                                                                <Icon
                                                                    iconType={IconType.PLUS_ICON}
                                                                    color="#7D8592"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            <div className="flex gap-2 items-center cursor-pointer" onClick={() => { setIsAddCommissionOpen(item.id) }}>
                                                <Icon iconType={IconType.PLUS_ICON} color="#50B8E7" />
                                                <span className="font-inter-semibold text-[--theme] cursor-pointer"  >
                                                    {t("Add new insurance company")}
                                                </span>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            )
                        })
                    }

                </ConditionalRender>


            </DashboardBoxLayout>
            <AddNewCategory
                hocCurrentStep={1}
                onConfirm={() => { setIsAddCategoryOpen(false) }}
                hocisOpen={IsAddCategoryOpen}
                hocToggleModal={() => { setIsAddCategoryOpen(false) }}
                hocTitle={"New Commission"}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />

            <AddNewCommission
                hocCurrentStep={1}
                id={IsAddCommissionOpen}
                onConfirm={() => { setIsAddCommissionOpen(undefined) }}
                hocisOpen={IsAddCommissionOpen !== undefined}
                hocToggleModal={() => { setIsAddCommissionOpen(undefined) }}
                hocTitle={"New Commission"}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />
            <AddNewCommissionCompany
                hocCurrentStep={1}
                id={IsAddCommissionCompanyOpen?.id}
                onConfirm={() => { setIsAddCommissionCompanyOpen(undefined) }}
                hocisOpen={IsAddCommissionCompanyOpen !== undefined}
                hocToggleModal={() => { setIsAddCommissionCompanyOpen(undefined) }}
                hocTitle={`New ${IsAddCommissionCompanyOpen?.name} Commission`}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />
            <ManageCommissionModule
                setIsAddCommissionCompanyOpen={setIsAddCommissionCompanyOpen}
                setIsAddCommissionOpen={setIsAddCommissionOpen}
                currentStep={ManageModuleStep}
                setCurrentStep={setManageModuleStep}
                setHocCurrentStep={setManageModuleStep}
                setStepOnClose={1}
                hocCurrentStep={ManageModuleStep}
                data={IsManageModuleOpen}
                onConfirm={() => { setIsManageModuleOpen(undefined) }}
                hocisOpen={IsManageModuleOpen !== undefined}
                hocToggleModal={() => { setIsManageModuleOpen(undefined) }}
                hocTitle={ManageModuleStep === 1 ? IsManageModuleOpen?.name : ''}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[1150px]",
                    titleClassName: ""
                }}
            />

            {/* <DeleteModal
                deleteFunction={DeleteCommision}
                isLoading={isLoadingDelete}
                hocCurrentStep={1}
                data={IsDeleteCommissionOpen}
                onConfirm={() => { setIsDeleteCommissionOpen(undefined) }}
                hocisOpen={IsDeleteCommissionOpen !== undefined}
                hocToggleModal={() => { setIsDeleteCommissionOpen(undefined) }}
                hocTitle={""}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            /> */}

        </div>
    )
}

export default FinaceComissionView