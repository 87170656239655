import { useQuery } from "react-query"
import { getPermissionsPerCompany } from "api/User";


export const useCompanyPermissions = () => {
    return useQuery<Array<any> | any>(
        'companyPermissions',
        () => getPermissionsPerCompany(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}