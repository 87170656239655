import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown'
import EmptyModule from 'components/molecules/empty-module/EmptyModule'
// import ViewChangeButtonGroup from 'components/molecules/view-change/ViewChangeButtonGroup'
import ConfigureTableDropdown from 'components/organisms/leads/dropdowns/ConfigureTableDropdown'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FinancesTable from '../tables/FinancesTable'
import ExternalCostsModal from '../modals/ExternalCostsModal'
import { useExternalCosts } from 'lib/hooks/queries/Finances/useExternalCosts'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import { ExternalCostsChart } from '../charts/ExternalCostsChart'


type ExternalCostsProps = {
    total: string
    filterData: { year: number, month: number }
}

const ExternalCosts: FC<ExternalCostsProps> = ({ total, filterData }) => {

    //eslint-disable-next-line
    const [activeView, setActiveView] = useState<string>("listView");

    // const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false)
    const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false)

    const { t } = useTranslation()

    const { data, isLoading } = useExternalCosts({ filter_date: filterData })

    const [UpdateModalData, setUpdateModalData] = useState()

    const leadsTableHeaders = [

        {
            id: 1,
            Header: `${t('Type')}`,
            accessor: (row: any) =>
                row.type,

            Cell: ({ row }: any) => (
                <div className='cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.type}
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: `${t('Partner')}`,
            accessor: (row: any) =>
                row.partner.name,
            Cell: ({ row }: any) => (
                <div className='cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.partner.name}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: `${t('Unit')}`,
            accessor: (row: any) =>
                row.unit,
            Cell: ({ row }: any) => (
                <div className='cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.unit}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: `${t('Cost/Unit')}`,
            accessor: (row: any) =>
                row.cost_per_unit,
            Cell: ({ row }: any) => (
                <div className='text-[--theme] font-inter-semibold cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>

                    {row.original.cost_per_unit + ' CHF'}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: `${t('Piece')}`,
            accessor: (row: any) =>
                row.piece,
            Cell: ({ row }: any) => (
                <div className='cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.piece}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: `${t('Total (CHF)')}`,
            accessor: (row: any) =>
                row?.amount,
            Cell: ({ row }: any) => (
                <div className='text-[--theme] font-inter-semibold cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original?.amount + ' CHF'}
                </div>
            ),
            isChecked: true
        }
    ];

    const [cardsOrder, setCardsOrder] = useState(leadsTableHeaders);

    const [IsModalOpen, setIsModalOpen] = useState<boolean>(false)

    const [ModalStep, setModalStep] = useState(1)

    return (
        <DashboardBoxLayout>
            <div className='flex items-center justify-between gap-3 mb-5'>

                <DashboardBoxTitle title='External Costs' className='basis-full' />
                <div className="bg-[--theme] h-[48px] w-[48px] grid place-items-center rounded-[8px] cursor-pointer flex-none"
                    onClick={() => setIsModalOpen(true)}
                >
                    <Icon iconType={IconType.PLUS_ICON} />
                </div>
                <div className="flex-none">
                    <div className={`relative`}>
                        <FilterBorderedWrapper
                            className="cursor-pointer h-[48px] w-[48px] "
                            onClick={() => {
                                setConfigureTableOpen(!configureTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_BARS_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={configureTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setConfigureTableOpen}
                                showCloseButton={true}
                                title={t('Configure Table')}
                            >
                                <ConfigureTableDropdown
                                    setCardsOrder={setCardsOrder}
                                    cardsOrder={cardsOrder}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>

                {/* <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                {/* <FilterTableDropdown
                                    setFilterData={setFilterData}
                                    FilterData={filterData}
                                    setIsOpen={setFilterTableOpen}
                                    isAssignedLeads={isAssignLeadsActive === 'Assigned Leads'}
                                />
                                <>
                                    asd
                                </>
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div> */}
                {/* <div className="flex-none">
                    <ViewChangeButtonGroup
                        setSelectedTab={setActiveView}
                        ActiveTab={activeView}
                    />
                </div> */}
            </div>
            <div>
                <ConditionalRender condition={isLoading}>
                    <div className="relative py-10">
                        <LoadingScreen />
                    </div>
                </ConditionalRender>
                <ConditionalRender condition={!isLoading}>
                    {(data as any)?.data.length === 0
                        ? <EmptyModule title="External Costs" />
                        : activeView === 'listView' ? (
                            <>
                                <FinancesTable
                                    data={(data as any)?.data || []}
                                    dataCols={cardsOrder}
                                />
                                <div className='border-t-[1px] border-[#A2A1A8] border-opacity-20 pt-5'>
                                    <div className='flex items-center justify-between'>
                                        <span className='font-inter-semibold'>Total</span>
                                        <span className='font-inter-semibold text-[--theme]'>{total} CHF</span>

                                    </div>
                                </div>
                            </>
                        ) : <ExternalCostsChart />
                    }



                </ConditionalRender>
                {/* <EmptyModule title="External Costs" /> */}

            </div>

            <ExternalCostsModal
                hocCurrentStep={ModalStep}
                currentStep={ModalStep}
                clearData={true}
                setCurrentStep={setModalStep}
                setStepOnClose={1}

                data={UpdateModalData}
                onConfirm={() => { setIsModalOpen(false); setUpdateModalData(undefined); setModalStep(1) }}
                hocisOpen={IsModalOpen || UpdateModalData !== undefined}
                hocToggleModal={() => { setIsModalOpen(false); setUpdateModalData(undefined); setModalStep(1) }}
                hocTitle={
                    ModalStep === 1
                        ? UpdateModalData !== undefined
                            ? "Update External Cost"
                            : "Add External Cost"
                        : ""
                }
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />
        </DashboardBoxLayout>
    )
}

export default ExternalCosts