import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Label from "components/atoms/input/input-label/InputLabel";
import { useAssignLeads } from "lib/hooks/mutations/Leads/useAssignLeads";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import { useTranslation } from "react-i18next";

export interface IAssignedToProps {
  onConfirm: () => void;
  id: string;
}

const AssignedTo: FC<IAssignedToProps> = ({ onConfirm, id }) => {
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const { mutate: assignLead } = useAssignLeads();
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  useEffect(() => {
    getUsersByPermission({
      permissions: ['lead_view_view'],
    })
    .then((res) => {
      setFetchedUsers(res.users);
    })
    .catch(() => {});
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: Record<string, string[]>) => {
    assignLead({
      lead_ids: [id],
      assigned_to: data.assigned_to,
    });
    onConfirm();
  };
  
  const options =
    fetchedUsers?.map((user: any) => ({
      value: user.id,
      label: user.name,
    })) ?? [];

    const {t} = useTranslation();

  return (
    <Form
      className="mb-0 flex flex-col gap-7"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <Label>{t('Assigned to*')}</Label>
        </div>
        <div>
          <div className="relative">
            <Controller
              name="assigned_to"
              rules={{ required: `${t('Assigned to is required')}` }}
              control={control}
              render={({ field }) => (
                <Creatable<any, false>
                  {...field}
                  placeholder={t('Choose a user')}
                  options={options}
                  value={
                    options.find((option) => option.value === field.value) || ""
                  }
                  defaultValue={options[0]}
                  className="cursor-pointer font-inter-regular react-select-container "
                  classNamePrefix="react-select"
                  onChange={(newValue: any, actionMeta: any) => {
                    if (
                      actionMeta.action === "select-option" &&
                      newValue?.value
                    ) {
                      field.onChange(newValue.value);
                    }
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
            {errors.assigned_to?.message && (
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                <>{errors.assigned_to?.message}</>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
      </div>
      <div className="flex gap-5 justify-end">
        <div>
          <IconButton
            onClick={() =>
              reset({
                assigned_to: "",
              })
            }
            secondary={true}
            className="w-full max-w-[150px]"
          >
            {t('Reset')}
          </IconButton>
        </div>
        <div>
          <IconButton type="submit" className="w-full max-w-[150px]">
            {t('Continue')}
          </IconButton>
        </div>
      </div>
    </Form>
  );
};

export default AssignedTo;
