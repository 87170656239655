import IconButton from 'components/atoms/button/IconButton';
import Checkbox from 'components/atoms/checkbox/Checkbox';
import Icon from 'components/atoms/icon/Icon';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import SalariesTable from 'components/organisms/finance-salaries/salaries-table/SalariesTable';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useRegisterUser } from 'lib/hooks/mutations/RegisterUser/useRegisterUser';
import { useRolesOfCompany } from 'lib/hooks/queries/Employee/useRolesOfCompany';
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

interface IStep2ChooseRoleProps {
    setCurrentStep: (step: number) => void;
    setAllData: (data: any) => void;
    AllData: any;
}

const Step2ChooseRole: FC<IStep2ChooseRoleProps> = ({ setCurrentStep, AllData, setAllData }) => {
    const { t } = useTranslation()


    const tableHeaders = [
        {
            id: 0,
            Header: ``,
            accessor: `${t('Not available')}`,
            Cell: ({ row }: any) => (

                <Checkbox id={row.original.id}
                    isChecked={AllData?.role === row.original.id}

                    onChange={(e: any) => {
                        if (e.target.checked) {
                            setAllData({ ...AllData, role: row.original.id })
                        }
                        else {
                            setAllData({ ...AllData, role: null })

                        }
                    }}
                />


            ),
            isChecked: true
        },
        {
            id: 1,
            Header: `${t('Assigned Workers')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer flex">
                    {row.original?.users?.length > 0 ? (
                        row.original?.users.map((user: any, index: number) => (
                            index < 5 && (
                                user.user_information?.profil_picture !== null ? (
                                    <img src={'https://devmainhub.insusales.com/api/profile-picture/' + user.user_information?.profil_picture} alt=""
                                        className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full`} key={index} />
                                ) : (
                                    <img src={'https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg'}
                                        alt="" className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full`} key={index} />

                                )
                            )
                        ))

                    ) : (
                        t('Not available')
                    )}

                    {(row.original?.users?.length > 5) && (
                        <div className="w-[35px] h-[35px] rounded-full -ml-3 bg-[--theme-bg-opacity] border-2 border-white text-[--theme] grid place-items-center font-inter-medium text-xs">
                            {row.original?.users.length - 5}
                        </div>
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: `${t('Name')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.name}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: `${t('Created at')}`,
            accessor: (row: any) =>
                row?.created_at,
            Cell: ({ row }: any) => (
                new Date(row.original?.created_at).toLocaleDateString('de')
            ),
            isChecked: true
        }


    ]

    const [ReRenderedCards, setReRenderedCards] = useState(tableHeaders)

    useEffect(() => {
        setReRenderedCards(tableHeaders)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AllData])

    const { data, isLoading } = useRolesOfCompany()

    const { mutateAsync, isLoading: isLoadingRegister } = useRegisterUser()

    const CreateEmployee = () => {
        mutateAsync(AllData).then((res) => {

            setAllData({ ...AllData, user_id: res.id })
            setCurrentStep(3)
        })
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
                <SalariesTable
                    data={(data as any)?.roles || []}
                    dataCols={ReRenderedCards}
                    background='transparent'
                />
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => { setAllData({ ...AllData, role: null }) }} secondary disabled={isLoadingRegister}>
                    {t(`Reset`)}
                </IconButton>

                <IconButton className="" type='button' onClick={CreateEmployee} disabled={AllData?.role === null || AllData?.role === undefined || isLoadingRegister}>
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </>
    )
}

export default Step2ChooseRole