import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getRolesOfCompany } from "api/Employee";

export const useRolesOfCompany = () => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['rolesOfCompany'],
        () => {
            return getRolesOfCompany();
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
